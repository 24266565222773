/* Parent constainer div */
.home-page-parent-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  /* max-width: 1440px; */
  background-color: #fff;
  width: 100%;
  margin-top: 75px;
}

.outer {
  max-width: 1340px;
  align-self: center;
  /* padding: 0px 50px; */
}

.outer-full {
  width: 100%;
}

.inner {
  max-width: 1340px;
  align-self: center;
  width: 100%;
  /* padding: 0px 50px; */
}

/* INNER NON TRACK */
.inner-nt {
  max-width: 1340px;
  align-self: center;
  width: 100%;
  margin: 0px;
  /* padding: 0px 50px; */
}

/* banner section ----------------------------------------------------------------------------- */

.banner-container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.banner-card-img {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  width: 470px;
  height: 470px;
  border-radius: 12px;
  padding: 20px;
}

/* discount banner section ---------------------------------------------------------------- */

.discountBanner-container {
  /* padding: 0px 30px; */
  height: 144px;
  margin: 20px 0px;
  cursor: pointer;
}

.mktBanner2-container {
  padding: 0px 0px;
  height: 200px;
  margin: 30px 0px 0px;
  cursor: pointer;
}

.discountBanner-container img {
  width: 100%;
  border-radius: 10px;
  /* height: 144px; */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #01508f;
  padding: 20px 45px;
  border-radius: 8px;
  width: 1206px;
  height: 376px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.modal-content h2 {
  font-size: 32px;
  font-weight: 550;
  font-family: "Inter";
  color: #f5d707;
  width: 582px;
  height: 77px;
  line-height: 40px;
}

.modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #fff;
}

.modal-close-btn:hover {
  color: #000;
}

.modal-banner-img-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.model-banner-img {
  width: 360px;
  height: 180px;
  border-radius: 10px;
}

/* top packs container -------------------------------------------------------------------- */
.top-packs-container {
  display: flex;
  flex-direction: column;
  /* background-color: #f5f1e1; */
  /* padding: 20px; */
  margin-top: 15px;
  height: 780px;
}

.bg-black {
  background-image: url("https://hoopr-smash-images.s3.ap-south-1.amazonaws.com/web/default-img/bg-1.png");
  background-repeat: repeat-x;
  background-position: top;
}

.bg-blue {
  /* background-image: url("https://hoopr-smash-images.s3.ap-south-1.amazonaws.com/web/default-img/bg-blue-1-200.png"); */
  /* background-image: url("https://hoopr-smash-images.s3.ap-south-1.amazonaws.com/web/default-img/bg-1.png"); */
  background-image: url("https://hoopr-smash-images.s3.ap-south-1.amazonaws.com/web/default-img/bg-dark-1-230.png");
  background-repeat: repeat-x;
  background-position: top;
  height: 200px;
}

.top-packs-heading {
  color: #010101;
  font-size: 36px;
  font-weight: bold;
  font-family: "Inter";
  letter-spacing: -1.5px;
}

.top-packs-description-view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.top-packs-description-view h2 {
  color: #010101;
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter";
  opacity: 80%;
}

.top-packs-viewall p {
  color: #373737;
  font-size: 16px;
  font-weight: 550;
  font-family: "Raleway";
  margin: 0;
}

.top-packs-viewall {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
  margin-right: 15px;
}

/* trending section container --------------------------------------------------- */

.trending-now-container {
  /* display: flex;
  flex-direction: column; */
  padding: 20px 0px;
  height: 550px;
  /* margin-top: 20px; */
  padding-right: 0px;
}

.trending-song-container {
  display: flex;
  flex-direction: row;
  gap: 1px;
}

.trending-song-container h1 {
  letter-spacing: -1.5px;
}

.trending-views {
  width: 78px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #fe4654;
  color: white;
  position: relative;
  padding: 10px;
}

.trending-views p {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  font-size: 28px;
  font-weight: bold;
  font-family: "Inter";
  margin: 0;
  margin-top: 20px;
  color: #f3f5f4;
}

.trending-icon {
  /* width: 30px;
  height: 30px; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 10px;
}

.trending-icon img {
  width: 54px;
  height: 54px;
}

.trending-image {
  width: 350px;
  height: 539px;
}

.trending-image img {
  width: 350px;
  height: 100%;
}

.trending-track-list-container {
  width: 100%;
  padding-top: 30px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
}

.trending-icon-header {
  width: 43px;
  height: 43px;
}

.trending-track-list-container h1 {
  color: #0a0a0a;
  font-size: 32px;
  font-weight: bold;
  font-family: "Inter";
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.trending-description-view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.trending-description-view h2 {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter";
  opacity: 80%;
}

.trending-viewall {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
  margin-right: 35px;
}

.trending-viewall p {
  color: #373737;
  font-size: 16px;
  font-weight: 550;
  font-family: "Raleway";
  margin: 0;
}

.view-white p {
  color: #fff;
}

.trending-track-cards {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
  /* width: calc(4 * 211px + 3 * 20px); */
}

/* brand category container ----------------------------------------------------- */

.brand-category-container {
  display: flex;
  flex-direction: row;
  background-color: #f5f1e1;
  padding: 20px 30px 30px;
  margin-top: 20px;
  letter-spacing: -1.5px;
  /* height: 700px; */
}

.brand-category-container h1 {
  color: #010101;
  /* font-size: 36px; */
  font-weight: bold;
  font-family: "Inter";
}

.brand-description-view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.brand-description-view h2 {
  color: #010101;
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter";
  opacity: 80%;
}

/* .brand-viewall p {
  color: #373737;
  font-size: 16px;
  font-weight: 550;
  font-family: "Raleway";
  margin: 0;
} */

.brand-viewall {
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
  margin-right: 15px; */
  font-family: "Raleway";
  font-size: 16px;
  font-weight: 550;
  color: #010101;
  letter-spacing: 0px;
}

.brand-card-container {
  display: flex;
  flex-wrap: wrap;
  /* gap: 15px; */
  margin-top: 20px;
  justify-content: space-between;
}

.brand-card-img {
  height: 254px;
  /* width: calc(20% - 15px);  */
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #17243a; */
}

/* feature moods and genres section -------------------------------------------------- */

.feature-mood-genre-container {
  display: flex;
  margin-top: 20px;
  height: 640px;
  width: 100%;
  margin-bottom: 20px;
}

.inner-mg-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.feature-mood-container {
  width: 794px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.feature-mood-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.feature-mood-title h1 {
  font-family: "Inter";
  font-weight: bold;
  /* font-size: 36px; */
  color: #010101;
  letter-spacing: -1.5px;
}

.feature-mood-viewall {
  font-family: "Raleway";
  font-size: 16px;
  font-weight: 550;
  color: #010101;
}

.feature-mood-card-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.feature-mood-card {
  /* width: 258px; */
  /* height: 271px; */
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.feature-mood-card-img {
  width: 255px;
  height: 255px;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
}

.feature-mood-card p {
  font-size: 18px;
  font-family: "Raleway";
  font-weight: bold;
  color: #000;
}

.feature-genre-container {
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.feature-genre-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.feature-genre-card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.feature-genre-card {
  height: 176px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 18px;
  font-family: "Raleway";
  font-weight: bold;
  color: #000;
}

.feature-genre-card-img {
  height: 180px;
  width: 500px;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  background-color: #373737;
  /* background-size: ; */
}

/* feature moods and genres section -------------------------------------------------- */

/* offer section -------------------------------------------------------------------------------- */
.offer-container {
  /* padding: 0px 30px; */
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  margin: 25px 0px;
}

.offer-card-img {
  width: 680px;
  height: 350px;
  border-radius: 10px;
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0px 20px;
  gap: 20px;
}

.offer-card-img h1 {
  font-size: 36px;
  font-weight: bold;
  font-family: "Inter";
  color: #fff;
}

.offer-card-img h2 {
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter";
  color: #fff;
}

.offercard-title {
  width: 50%;
  line-height: 40px;
}

.offercard-description {
  width: 50%;
}

/* feature tracks container --------------------------------------------------------------------- */

.feature-track-container {
  display: flex;
  flex-direction: column;
  background: linear-gradient(to right, #381573, #399eba);
  padding: 20px 30px 10px;
  margin-top: 10px;
  height: 520px;
}

.feature-track-container h1 {
  color: #fff;
  font-size: 36px;
  font-weight: bold;
  font-family: "Inter";
  letter-spacing: -1.5px;
}

.feature-track-description-view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.feature-track-description-view h2 {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter";
  opacity: 80%;
}

.feature-track-viewall p {
  color: #fff;
  font-size: 16px;
  font-weight: 550;
  font-family: "Raleway";
  margin: 0;
}

.feature-track-viewall {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
  margin-right: 15px;
}

.feature-track-card-container {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-top: 30px;
}

.feature-track-cards {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;
}

/* ig-embed------------------------------------------ */

.IG-embed {
  display: flex;
  height: 533px;
  margin-left: 5px;
}

.indie-heading {
  color: #ffee00;
  font-size: 56px;
}

.indie-h2 h2 {
  color: white;
}

.discount {
  background-color: yellow;
  font-size: 24px;
  padding: 3px 30px;
}

.discountR {
  background-color: #f84451;
  font-size: 24px;
  padding: 3px 30px;
  color: #fff;
}

.insta-container {
  /* display: flex;
  flex-direction: column; */
  padding: 20px 0px;
  height: 1000px;
  /* margin-top: 20px; */
  padding-right: 0px;
}
