@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

.toast {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #fff;
  color: #333;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  align-items: stretch;
  width: 380px;
  justify-content: space-between;
  min-height: 80px;
  font-family: Inter;
  transition: opacity 0.5s ease-in-out;
}

.icon {
  display: flex;
  flex: 0.8;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  color: #ffffff;
}

.messageContainer {
  flex: 3;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.messageHeader {
  font-size: 18px;
  margin: 0;
  flex: 1;
}

.message {
  font-size: 16px;
  margin-top: 4px;
}

.closeButton {
  cursor: pointer;
  margin: 10px 5px 0 0;
}

.slideIn {
  animation: slideIn 0.5s forwards;
}

.slideOut {
  animation: slideOut 1.5s forwards; /* Increase the duration to 1.5s */
}

/* Responsive styles */
@media (max-width: 768px) {
  .toast {
    width: 300px;
  }
}

@media (max-width: 480px) {
  .toast {
    width: 250px;
  }
}
