@keyframes shrinkAndMove {
  0% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(40vw, 40vh) scale(0.1);
    opacity: 0;
  }
}

@keyframes miniCircleAppear {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

/* Modal shrink and move */
.shrinkMoveAnimation {
  animation: shrinkAndMove 1s forwards;
}

.fadeOut {
  animation: fadeOut 1s forwards;
}

/* Mini Circle Fade-in */
.miniCircle {
  position: fixed;
  border-radius: 50px;
  right: 80px;
  bottom: 12px;
  opacity: 0;
  animation: miniCircleAppear 0.3s forwards 0.1s;
  cursor: pointer;
}

.miniCircle img {
  width: 210px;
  height: 63px;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: opacity 0.3s ease;
}

.modalContainer {
  width: 1190px;
  height: 549px;
  background: no-repeat center center;
  background-size: cover;
  border-radius: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-image: url(https://cdn.hooprsmash.com/web/promotional/footer/bmm.webp);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.innerContent {
  width: 450px;
  height: 100%;
  background: #ffffffdd;
  border-radius: 0px 10px 10px 0px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;
}

.closeIconMain {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.mheader {
  margin: 20px 0px;
  max-width: 298px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mHtxt {
  font-size: 26px;
  font-weight: bold;
  word-break: normal;
}

.mSubtxt {
  font-size: 14px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 25px;
}

@media (max-width: 768px) {
  .modalContainer {
    width: 700px;
    height: 80%;
  }
  .innerContent {
    width: 320px;
  }
  .mHtxt {
    font-size: 14px;
  }
  .mSubtxt {
    font-size: 12px;
  }
}

@media screen and (max-width: 481px) {
  .modalOverlay {
    padding: 0px 10px;
  }
  .modalContainer {
    width: 461px;
    height: 95%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    background-image: none;
    align-items: normal;
    justify-content: normal;
  }
  .innerContentM {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    background: linear-gradient(to bottom, #543885 0%, #3b79ac 100%);
    border-radius: 8px;
  }
  .formContainer {
    padding: 15px 0px;
  }
  .contentM {
    background: #ffffffdd;
    padding: 10px 20px;
  }
  .bmmmobileimg {
    width: 100%;
    height: 425px;
    display: block;
    margin: 0;
    padding: 0;
  }
  .mheader {
    margin: 0px;
    gap: 2px;
  }
  .miniCircle {
    right: 10px;
    bottom: 70px;
  }
  .miniCircle img {
    width: 60px;
    height: 60px;
  }
  .miniCircleWithPlayer {
    bottom: 150px;
  }
}
