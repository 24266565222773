.innerMp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.inner {
  max-width: 1340px;
  align-self: center;
  width: 100%;
  margin: auto;
}

.innerBreadcrumb {
  color: #d5d5d5;
  font-size: 14px;
  font-family: "Raleway";
  font-weight: 550;
}

.innerBreadcrumb span {
  color: #fff;
}

.outerFull {
  width: 100%;
}

/* cat details section starts here --------------------------------------------------- */

.container {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  margin-top: 65px;
  min-height: 100vh;
}

.dContainer {
  background-color: #000;
  display: flex;
  flex-direction: row;
  padding: 10px 0px 0px;
  margin: 0px 0px 20px;
  height: fit-content;
}

.dSection {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
}

.dContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.dImage {
  width: 198px;
  height: 198px;
  border-radius: 3px;
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pillsContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid #fff;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 20px;
  width: 100%;
  height: auto;
  gap: 20px;
}

.pills {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  gap: 10px;
}

.pillHtxt {
  color: #000;
  font-weight: 550;
  font-family: "Raleway";
  font-size: 14px;
}

.pills a {
  color: #000000dd;
  font-size: 16px;
  font-family: "Raleway";
  font-weight: 550;
  background: #ffffff77;
  padding: 8px 30px;
  border-radius: 5px;
  position: relative;
  letter-spacing: -0.25px;
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
}

.dHeader {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.nameShare {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.dHtxt {
  color: #fff;
  font-size: 50px;
  font-family: "Inter";
  font-weight: bold;
  /* opacity: 90%; */
}

.dStxt {
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
  color: #fff;
  opacity: 90%;
  width: 650px;
  padding-bottom: 40px;
}

.tracksContainer {
  display: flex;
  flex-direction: column;
  min-height: 400px;
}

.tracksHtxt {
  font-size: 30px;
  font-family: "Raleway";
  font-weight: bold;
  color: #000;
  margin: 65px 0px 20px;
}

.tracks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 20px;
}

.msgTxt {
  text-align: center;
  margin-top: 30px;
}

.viewMoreBtn {
  padding: 15px 45px;
  margin: 20px auto;
  display: block;
  font-size: 16px;
  color: white;
  background-color: #000;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.viewMoreBtn:hover {
  background-color: #000;
}

.rotateButton {
  position: fixed;
  left: 0; /* Align to the right */
  top: 30%; /* Center vertically */
  background: linear-gradient(gray, black);
  color: #fff;
  padding: 90px 0px 125px;
  border: none;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
  z-index: 1000; /* Ensure it's above other elements */
  font-size: 14px;
  display: flex; /* Use flexbox for alignment */
  flex-direction: column; /* Arrange items in a row */
  align-items: center;
  gap: 60px;
  width: 47px; /* Adjust width to content */
}

.rotateButton:hover {
  background-color: #555;
}

.rotateButton span {
  transform: rotate(90deg); /* Rotate the text inside the button */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  display: inline-block; /* Ensures the rotation is applied to the text */
  text-align: center; /* Center the text horizontally */
  font-size: 18px;
  color: #fff; /* Ensures text color is visible on dark background */
  text-shadow: 2px 2px 3px #000;
}

.filterIcon {
  color: #ffffff;
}

.filterActiveContainer {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: green;
}

.filterActive {
  color: white;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-start; /* Align modal to the left */
  align-items: center;
  z-index: 1000;
}

.modalOverlayWithPlayer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 90px); /* Adjusted height when global player is active */
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1000;
}

.modalContainer {
  width: 450px;
  height: 100vh;
  background-size: cover;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  align-items: center;
  background-color: #f7f7f7;
  animation: slideIn 0.3s ease-out; /* Animation added */
  position: fixed; /* Ensure it's positioned relative to the viewport */
  left: 0; /* Align to the left */
  right: unset; /* Remove any conflicting right alignment */
  margin: 0 auto; /* Center horizontally if needed */
  transform: none; /* Ensure no unwanted transformations */
}

.heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.header {
  flex: 0 0 60px; /* Fixed height, won't shrink */
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
}

.headingtext {
  font-size: 26px;
  font-family: "Raleway";
  font-weight: bold;
}

.filtersSection {
  width: 100%;
  padding: 8px 20px;
  height: auto;
  font-size: 20px;
}

.filterHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;
}

.sortOptions {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
  font-size: 16px;
}

.radioInput {
  margin-right: 10px;
}

.filterWapper {
  overflow-y: auto;
  width: 100%;
}

.desBottom {
  font-size: 14px;
  width: auto;
  color: #000;
  opacity: 85%;
}

.desDiv p {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin: 0px 0px 8px;
}

.desDiv {
  padding: 20px;
  margin: 20px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

/* .bgBlack {
  background-image: url("https://cdn.hooprsmash.com/web/default-img/bg-1.png");
  background-repeat: repeat-x;
  background-position: top;
} */

.nameShare {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.bgImage {
  align-items: flex-end;
  background-position: center;
  background-size: cover;
  display: flex;
  height: 275px;
  justify-content: flex-end;
  margin: 0px 20px 0px 0px;
  opacity: 0.9;
  position: relative;
  width: 275px;
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@media screen and (max-width: 768px) {
  .rotateButton {
    padding: 30px 0px 70px;
    top: 57%;
    width: 30px;
    font-size: 14px;
  }
  .filterActiveContainer {
    width: 25px;
    height: 25px;
  }
  .inner {
    width: 100%;
  }
  .dSection {
    height: 168px;
  }
  .dHtxt {
    font-size: 28px;
  }
  .dStxt {
    font-size: 13px;
    width: auto;
  }
  .tracksContainer {
    padding: 0 20px;
  }
}

@media screen and (max-width: 481px) {
  .rotateButton {
    top: 36%;
    width: 30px;
  }
  .filterActiveContainer {
    width: 25px;
    height: 25px;
  }
  .inner {
    width: 100%;
  }
  .innerMp {
    padding: 0px 30px;
  }
  .dSection {
    height: 128px;
  }
  .dHtxt {
    font-size: 24px;
  }
  .dStxt {
    font-size: 11px;
    width: auto;
  }
  .tracksContainer {
    padding: 0 18px;
  }
}

@media screen and (max-width: 375px) {
  .rotateButton {
    top: 44%;
    width: 25px;
  }
  .filterActiveContainer {
    width: 25px;
    height: 25px;
  }
  .inner {
    width: 100%;
  }
  .dSection {
    height: 128px;
  }
  .dHtxt {
    font-size: 20px;
  }
  .dStxt {
    font-size: 10px;
    width: auto;
  }
  .tracksContainer {
    padding: 0 10px;
  }
}

@media screen and (max-width: 320px) {
  .rotateButton {
    top: 44%;
    width: 25px;
  }
  .filterActiveContainer {
    width: 25px;
    height: 25px;
  }
  .inner {
    width: 100%;
  }
  .dSection {
    height: 128px;
  }
  .dHtxt {
    font-size: 18px;
  }
  .dStxt {
    font-size: 10px;
    width: auto;
  }
  .tracksContainer {
    padding: 0 8px;
  }
}
