.packs-cards-container {
  --packs-container-height: max-content;
  --pack-title-font-size: 16px;
  --pack-title-font-weight: 550;

  --pack-card-img-width: 436px;
  --pack-card-img-height: 220px;

  --card-track-total-height: 50px;
  --card-track-total-width: 50px;
  --track-total-font-size: 24px;
  --track-total-font-weight: bolder;
  --track-label-font-size: 8px;
  --track-label-font-weight: bold;

  /* --discount-percentage-width: 55px; */
  --discount-percentage-width: auto;
  --discount-percentage-height: 17px;
  --discount-percentage-font-size: 11px;
  --discount-percentage-font-weight: bold;

  --discount-amount-font-size: 22px;
  --discount-amount-font-weight: bold;
  --discount-label-font-size: 11px;
  --discount-label-font-weight: 400;

  --cost-font-size: 10px;
  --cost-font-weight: 400;

  --explore-btn-width: 110px;
  --explore-btn-height: 35px;
  --explore-btn-font-size: 12px;
  --explore-btn-font-weight: bold;

  --cart-btn-height: 37px;
  --cart-btn-width: 37px;
}

/* Specific Modifications */
.packs-cards-container[data-type="allpacks"] {
  --packs-container-height: max-content;
}

.packs-cards-container[data-type="similartrackpack"] {
  --packs-container-height: 303px;
  --pack-title-font-size: 20px;
  --pack-card-img-width: 434px;
  --pack-card-img-height: 214px;
}

.packs-cards-container[data-type="savemoretrack"] {
  --packs-container-height: 229px;
  --pack-card-max-width: 336px;
  --pack-title-font-weight: bold;
  --pack-card-img-width: 332px;
  --pack-card-img-height: 161px;
  --track-total-font-size: 22px;
  --discount-amount-font-size: 18px;
}

.packs-cards-container[data-type="toppackhome"] {
  --explore-btn-width: 125px;
}

.packs-cards-container[data-type="likespacks"] {
  --pack-card-img-width: 365px;
  --pack-card-max-width: 365px;
  --explore-btn-width: 105px;
  --pack-card-img-height: 181px;
}

@media screen and (max-width: 768px) {
  .packs-cards-container[data-type="pop"] {
    --pack-card-max-width: 370px;
    --pack-card-img-width: 350px;
    --explore-btn-width: 130px;
    --pack-card-img-height: 160px;
    justify-content: center;
  }
  .packs-cards-container[data-type="allpacks"] {
    --pack-card-max-width: 370px;
    --pack-card-img-width: 350px;
    --explore-btn-width: 130px;
    --pack-card-img-height: 160px;
    justify-content: center;
  }
  .packs-cards-container[data-type="similartrackpack"] {
    --pack-card-max-width: 370px;
    --pack-card-img-width: 350px;
    --explore-btn-width: 130px;
    --pack-card-img-height: 160px;
    --packs-container-height: auto;
  }
}

@media screen and (max-width: 320px) {
  .packs-cards-container[data-type="pop"] {
    --pack-card-max-width: 280px;
    --pack-card-img-width: 278px;
    --explore-btn-width: 260px;
    --pack-card-img-height: 135px;
    justify-content: center;
    --discount-amount-font-size: 16px;
    --explore-btn-width: 95px;
  }
  .packs-cards-container[data-type="allpacks"] {
    --pack-card-max-width: 310px;
    --pack-card-img-width: 308px;
    --explore-btn-width: 290px;
    --pack-card-img-height: 150px;
    justify-content: center;
  }
  .packs-cards-container[data-type="similartrackpack"] {
    --pack-card-max-width: 310px;
    --pack-card-img-width: 308px;
    --explore-btn-width: 290px;
    --pack-card-img-height: 150px;
    justify-content: center;
  }
}
