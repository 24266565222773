.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1000;
}

.modalContainer {
  width: 450px;
  height: 100%;
  background-size: cover;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  animation: slideIn 0.3s ease-out;
  padding-bottom: 15px;
}

.content {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  /* background-color: #ece2c3; */
  max-height: 90vh;
  padding: 20px;
}

.formgroup {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.header {
  flex: 0 0 65px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  /* min-height: 65px; */
}

.headingtext {
  font-size: 26px;
  font-family: "Raleway";
  font-weight: bold;
}

.subHeading {
  padding: 0px 20px;
  font-size: 14px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
  /* overflow-y: auto; */
  flex-grow: 1;
  max-height: calc(100vh - 100px); /* Ensures form is scrollable */
  /* padding-bottom: 80px; Adds space to prevent button from getting cut off */
}

.formHeading {
  font-weight: bold;
  font-size: 14px;
}

.phoneInput {
  /* margin-top: 20px; */
  display: flex;
  gap: 0px;
  border: 1px solid #bfbfbf;
  border-radius: 5px;
  /* padding: 0px 15px; */
  align-items: center;
}

.countryCode {
  border: none;
  font-size: 18px;
  background: none;
  border-right: 1px solid #bfbfbf;
  padding: 0px 10px;
  height: 50px;
  background-color: #fff;
}

.phoneNumber {
  border: none;
  outline: none;
  width: 100%;
  height: 50px;
  font-size: 18px;
  padding: 0px 10px;
  letter-spacing: 1px;
  border-radius: 4px;
}

.textarea {
  width: 100%;
  height: 150px;
  padding: 8px;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  font-size: 16px;
  font-family: Inter;
  resize: none;
}

.errorMsg {
  color: red;
  font-size: 14px;
  margin-top: -8px;
  font-weight: bold;
}
