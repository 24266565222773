.parentContainer {
  display: flex;
  flex-direction: column;
  margin: auto;
  background-color: #fff;
  width: 100%;
  margin-top: 75px;
}

.outer {
  max-width: 1340px;
  align-self: center;
}

.outerFull {
  width: 100%;
}

.inner {
  max-width: 1340px;
  align-self: center;
  width: 100%;
}

/* INNER NON TRACK */
.innerNt {
  max-width: 1340px;
  align-self: center;
  width: 100%;
  margin: 0px;
}

/* MARKETING BANNERS FOR BUDGET FRIENDLY TRACKS */
.budgetContainer {
  display: flex;
  flex-direction: row;
  gap: 15px;
  height: fit-content;
  padding: 50px 0px 20px;
}

.budgetImage {
  width: 435px;
  height: 198px;
  border-radius: 10px;
  box-shadow: 0px 6px 6px #00000055;
}

/* top banner section ----------------------------------------------------------------------------- */

.bannerContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.bannerCardImg {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  width: 435px;
  height: 470px;
  border-radius: 12px;
  padding: 20px;
}

/* MARKETING BANNER 1 css */

.mktBanner2Container {
  padding: 0px 0px;
  /* height: 200px; */
  margin: 20px 0px 0px;
  cursor: pointer;
  width: 1340px;
}

.mkt2image {
  border-radius: 10px;
  width: 100%;
}

.abtSearchImgL {
  width: 100%;
  border-radius: 10px;
}

/* TRACKS SECTION ----------------------------------------------------------------------------- */

.trackParentContainer {
  padding: 20px 0px;
  /* padding-right: 0px; */
}

.tpHeader {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.tpHeadertext {
  letter-spacing: -1.5px;
}

.textWhite {
  color: #fff;
}

.discountR {
  background-color: #f84451;
  font-size: 24px;
  padding: 3px 30px;
  color: #fff;
  margin-left: 5px;
  display: none;
}

.tpSubHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tpSubHeaderText {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter";
  opacity: 80%;
  /* padding: 0px 10px; */
}

.tpSubHeaderTextB {
  color: #000000;
}

.trackCards {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.viewall {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
  /* margin-right: 35px; */
}

.viewalltext {
  color: #373737;
  font-size: 16px;
  font-weight: 550;
  font-family: "Raleway";
  margin: 0;
}

.viewalltextwhite {
  color: #fff;
  font-size: 16px;
  font-weight: 550;
  font-family: "Raleway";
  margin: 0;
}

.instaContainer {
  height: max-content;
  margin-top: 10px;
  /* background: linear-gradient( 140deg, #7e55d6 0%, #dc6692 36%, #e1c092 100%); */
  background: radial-gradient(#393e52 0%, #3f7f8f 40%, #5fb883 100%);
  display: flex;
  justify-content: center;
  padding: 40px 0px;
}

.cricketContainer {
  height: max-content;
  margin-top: 10px;
  /* background: linear-gradient( 140deg, #7e55d6 0%, #dc6692 36%, #e1c092 100%); */
  /* background: linear-gradient(#D8F0F6 0%, #fff 100%); */
  display: flex;
  justify-content: center;
  padding: 40px 0px;
}

.containerHeight {
  height: 550px;
  margin-top: 10px;
}

.bgBlue {
  background-image: url("https://hoopr-smash-images.s3.ap-south-1.amazonaws.com/web/default-img/bg-dark-1-230.png");
  background-repeat: repeat-x;
  background-position: top;
  /* height: 200px; */
  /* background: linear-gradient(#3643e1 0%, #3643e1 30%, #FFF 50%) */
  /* background: radial-gradient(to bottom, #381573 0%, #FFF 55%); */
  /* background: radial-gradient(#381573 0%, #FFF 70%); */
  /* background-color: #FFF; */
}

.indieHeading {
  color: #ffee00;
  font-size: 56px;
}

.viewWhitep {
  color: #fff;
}

.ftContainer {
  display: flex;
  flex-direction: column;
  background: linear-gradient(to right, #381573, #399eba);
  margin-top: 10px;
  height: 520px;
}

.ftHeading {
  color: #fff;
  font-size: 36px;
  font-weight: bold;
  font-family: "Inter";
  letter-spacing: -1.5px;
  margin-top: 10px;
}

.indiContainer {
  display: flex;
  justify-content: center;
}

/* brand category container ----------------------------------------------------- */

.brandContainer {
  display: flex;
  flex-direction: row;
  background-color: #f5f1e1;
  padding: 20px 30px 30px;
  margin-top: 20px;
  letter-spacing: -1.5px;
  justify-content: center;
  height: auto;
}

.bcHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.bcHeaderText {
  color: #010101;
  font-weight: bold;
  font-family: "Inter";
}

.bcViewAll {
  font-family: "Raleway";
  font-size: 16px;
  font-weight: 550;
  color: #010101;
  letter-spacing: 0px;
  display: flex;
  align-items: center;
}

.bcCards {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: space-between;
}

.brandCardImg {
  height: 254px;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* feature moods and genres section -------------------------------------------------- */

.mgContainer {
  display: flex;
  margin-top: 20px;
  height: auto;
  width: 100%;
  margin-bottom: 20px;
}

.innerMgContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.moodContainer {
  width: 794px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.mgHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.mgHeaderText {
  font-family: "Inter";
  font-weight: bold;
  color: #010101;
  letter-spacing: -1.5px;
}

.moodCardContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.moodCardImg {
  width: 255px;
  height: 255px;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
}

.moodCardHeading {
  font-size: 18px;
  font-family: "Raleway";
  font-weight: bold;
  color: #000;
}

.genreContainer {
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.genreCardContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.mgCards {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.genreCardImg {
  height: 180px;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  background-color: #373737;
}

/* packs container -------------------------------------------------------------------- */
.packsContainer {
  display: flex;
  flex-direction: column;
  /* margin-top: 15px; */
  height: 780px;
  margin: 15px 0px;
}

.packsHeading {
  color: #010101;
  font-size: 36px;
  font-weight: bold;
  font-family: "Inter";
  letter-spacing: -1.5px;
}

.discount {
  background-color: yellow;
  font-size: 24px;
  padding: 3px 30px;
  margin-left: 5px;
  display: none;
}

.packHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}

.packSubHeading {
  color: #010101;
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter";
  opacity: 80%;
}

.allSongs {
  margin-top: 35px;
  background: linear-gradient(to bottom, #f5f1e1 5%, #fff 90%);
}

/* watch video modal */
.modalOverlay {
  position: fixed;
  top: 0;
  /* left: 0; */
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContainer {
  width: 970px;
  height: 546px;
  background-size: cover;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  align-items: center;
  background-color: #f7f7f7;
  /* animation: slideIn 0.3s ease-out; Animation added */
}

.introVideo {
  width: 970px;
  height: 546px;
}

.modalClose {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 54px;
  color: #fff;
}

.fireIcon {
  height: 43px;
  width: 42px;
  margin-top: 5px;
}

/* tablet view css start from here  -------------------------------------------*/
@media (max-width: 768px) {
  .outer {
    max-width: 100%;
    width: 100%;
    padding: 0 10px;
  }
  .inner {
    width: 100%;
    padding: 0;
  }
  .bannerCardImg {
    min-height: 380px;
    min-width: 300px;
    max-width: 367px;
  }
  /* .instaContainer {
    display: none;
  } */
  .viewall {
    margin-right: 0px;
  }
  .containerHeight {
    height: auto;
  }
  .ftContainer {
    height: auto;
  }
  .brandContainer {
    padding: 20px 10px 10px;
  }
  .bcCards {
    justify-content: flex-start;
    gap: 20px;
  }
  .brandCardImg {
    height: 216px;
  }
  .genreContainer {
    width: 300px;
  }
  .genreCardImg {
    height: 140px;
  }
  .mgHeaderText {
    font-size: 1.4em;
  }
  .bcViewAll {
    font-size: 14px;
  }
  .moodContainer {
    width: 400px;
  }
  .moodCardImg {
    width: 190px;
    height: 122px;
  }
  .moodCardHeading {
    font-size: 14px;
  }
  .mgHeaderText {
    letter-spacing: -0.5px;
  }
  .packsHeading {
    font-size: 30px;
  }
  .discount {
    font-size: 18px;
  }
  .packSubHeading {
    font-size: 12px;
  }
  .packsContainer {
    height: 350px;
  }
  .modalContainer,
  .introVideo {
    width: 500px;
    height: 300px;
  }

  .budgetContainer {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    margin: 25px 0px 15px 22px;
    justify-content: center;
  }

  .budgetImage {
    width: 350px;
    height: 160px;
    border-radius: 10px;
    /* box-shadow: 0px 4px 4px #000000; */
  }
}

/* mobile view css start from here  -------------------------------------------*/
@media (max-width: 481px) {
  .outer {
    max-width: 95%;
    width: 95%;
    padding: 0;
  }
  .inner {
    width: 100%;
    padding: 0;
  }
  .bannerCardImg {
    height: 450px;
    max-width: 99%;
  }
  /* .instaContainer {
    display: none;
  } */
  .viewall {
    margin-right: 0px;
    justify-content: left;
    padding: 10px 10px 0px 0px;
  }

  .viewalltextwhite {
    font-size: 14px;
  }

  .tpHeadertext {
    font-size: 22px;
    padding: 0px 10px;
  }
  .tpSubHeader {
    margin: 10px 0px 0px 10px;
    flex-direction: column;
  }
  .tpSubHeaderText {
    font-size: 14px;
  }
  .viewalltext {
    font-size: 12px;
  }
  .containerHeight {
    height: auto;
  }
  .ftContainer {
    height: auto;
  }
  .brandContainer {
    padding: 20px 10px 10px;
  }
  .bcCards {
    justify-content: center;
    gap: 20px;
  }
  .brandCardImg {
    height: 172px;
  }
  .genreContainer {
    /* width: 200px; */
    width: 100%;
  }
  .genreCardImg {
    height: 120px;
  }
  .bcViewAll {
    font-size: 12px;
  }
  .moodCardContainer {
    justify-content: center;
  }
  .moodContainer {
    width: 100%;
    margin-top: 20px;
  }
  .moodCardImg {
    width: 116px;
    height: 116px;
  }
  .moodCardHeading {
    font-size: 14px;
  }
  .mgHeaderText {
    letter-spacing: -0.5px;
    font-size: 1em;
  }
  .packsHeading {
    font-size: 22px;
  }
  .discount {
    font-size: 18px;
    padding: 3px 6px;
  }
  .packSubHeading {
    font-size: 11px;
    width: 65%;
  }
  .packsContainer {
    height: 335px;
  }
  .bcHeaderText {
    font-size: 20px;
  }
  .innerMgContainer {
    flex-direction: column;
    gap: 10px;
  }
  .mobile {
    padding: 0px 2px;
    padding-top: 30px;
  }
  .mobileTrend {
    padding: 30px 2px;
  }
  .ftmobile {
    padding: 0px 12px;
  }
  .ftHeading {
    font-size: 24px;
  }

  .modalContainer,
  .introVideo {
    width: 300px;
    height: 200px;
  }

  .budgetContainer {
    flex-direction: column;
    gap: 10px;
    padding: 15px 5px;
    max-width: 95%;
  }

  .budgetImage {
    /* width: 380px; */
    width: 95%;
    height: 173px;
  }
}

@media (max-width: 430px) {
  .budgetContainer {
    flex-direction: column;
    gap: 10px;
    padding: 15px 5px;
    max-width: 95%;
  }

  .budgetImage {
    /* width: 408px; */
    width: 95%;
    height: 187px;
  }

  .tpSubHeader {
    margin: 10px 0px 0px 10px;
  }

  .viewall {
    margin-right: 0px;
    justify-content: left;
    padding: 10px 10px 0px 0px;
  }
}

@media (max-width: 412px) {
  .budgetContainer {
    flex-direction: column;
    gap: 10px;
    padding: 15px 0px;
    max-width: 95%;
  }

  .budgetImage {
    /* width: 390px; */
    width: 95%;
    height: 178px;
  }

  .tpSubHeader {
    margin: 10px 0px 0px 10px;
  }

  .viewall {
    margin-right: 0px;
    justify-content: left;
    padding: 10px 10px 0px 0px;
  }
}

@media (max-width: 395px) {
  .budgetContainer {
    flex-direction: column;
    gap: 10px;
    padding: 15px 2px;
    max-width: 95%;
  }

  .budgetImage {
    /* width: 365px; */
    width: 95%;
    height: 167px;
  }

  .tpSubHeader {
    margin: 10px 0px 0px 10px;
  }

  .viewall {
    margin-right: 0px;
    justify-content: left;
    padding: 10px 10px 0px 0px;
  }
}

@media (max-width: 375px) {
  .outer {
    max-width: 100%;
    width: 100%;
    padding: 0 5px;
  }
  .bannerCardImg {
    height: 395px;
    max-width: 99%;
    /* padding: 0px 10px; */
  }
  /* .instaContainer {
    display: none;
  } */
  .viewall {
    margin-right: 0px;
  }

  .viewalltextwhite {
    font-size: 14px;
  }

  .tpHeadertext {
    font-size: 18px;
    padding: 0px 8px;
  }
  .tpSubHeader {
    margin: 10px 0px 0px 10px;
  }
  .tpSubHeaderText {
    font-size: 14px;
  }
  .viewalltext {
    font-size: 11px;
  }
  .containerHeight {
    height: auto;
  }
  .ftContainer {
    height: auto;
  }
  .brandContainer {
    padding: 20px 10px 10px;
  }
  .bcCards {
    justify-content: center;
    gap: 20px;
  }
  .brandCardImg {
    height: 158px;
  }
  .genreContainer {
    /* width: 300px; */
    width: 100%;
  }
  .genreCardImg {
    height: 120px;
  }
  .bcViewAll {
    font-size: 12px;
  }
  .moodCardContainer {
    justify-content: center;
  }
  .moodContainer {
    width: auto;
  }
  .moodCardImg {
    width: 106px;
    height: 106px;
  }
  .moodCardHeading {
    font-size: 14px;
  }
  .mgHeaderText {
    letter-spacing: -0.5px;
    font-size: 1em;
  }
  .packsHeading {
    font-size: 22px;
  }
  .discount {
    font-size: 18px;
  }
  .packSubHeading {
    font-size: 11px;
    width: 65%;
  }
  .packsContainer {
    height: 310px;
  }
  .innerMgContainer {
    flex-direction: column;
    gap: 10px;
  }
  .mobile {
    padding: 0px 4px;
    padding-top: 30px;
  }
  .mobileTrend {
    padding: 30px 4px;
  }
  .ftmobile {
    padding: 0px 4px;
  }
  .ftHeading {
    font-size: 22px;
  }

  .budgetContainer {
    flex-direction: column;
    gap: 10px;
    padding: 15px 0px;
    max-width: 95%;
    justify-content: center;
  }

  .budgetImage {
    /* width: 350px; */
    width: 95%;
    height: 160px;
  }
}

@media (max-width: 360px) {
  .budgetContainer {
    flex-direction: column;
    gap: 10px;
    padding: 15px 0px;
  }

  .budgetImage {
    /* width: 340px; */
    width: 95%;
    height: 167px;
  }

  .tpSubHeader {
    margin: 10px 0px 0px 10px;
  }

  .viewall {
    margin-right: 0px;
    justify-content: left;
    padding: 10px 10px 0px 0px;
  }
}

@media (max-width: 320px) {
  .outer {
    max-width: 100%;
    width: 100%;
    padding: 0 5px;
  }
  .bannerCardImg {
    height: 390px;
    max-width: 310px;
  }
  /* .instaContainer {
    display: none;
  } */
  .viewall {
    margin-right: 0px;
  }

  .viewalltextwhite {
    font-size: 14px;
  }

  .tpHeadertext {
    font-size: 18px;
    padding: 0px 5px;
  }
  .discountR {
    font-size: 16px;
  }
  .tpSubHeader {
    margin: 10px 0px 0px 10px;
  }
  .tpSubHeaderText {
    font-size: 12px;
  }
  .viewalltext {
    font-size: 10px;
  }
  .containerHeight {
    height: auto;
  }
  .ftContainer {
    height: auto;
  }
  .brandContainer {
    padding: 20px 10px 10px;
  }
  .bcCards {
    justify-content: center;
    gap: 20px;
  }
  .brandCardImg {
    height: 138px;
  }
  .genreContainer {
    width: 100%;
  }
  .genreCardImg {
    height: 50px;
  }
  .bcViewAll {
    font-size: 10px;
  }
  .moodContainer {
    width: 400px;
  }
  .moodCardImg {
    width: 76px;
    height: 41px;
  }
  .moodCardHeading {
    font-size: 8px;
  }
  .mgHeaderText {
    letter-spacing: -0.5px;
    font-size: 1em;
  }
  .packsHeading {
    font-size: 18px;
  }
  .discount {
    font-size: 18px;
  }
  .packSubHeading {
    font-size: 11px;
    width: 65%;
    margin-top: 10px;
  }
  .packsContainer {
    height: 310px;
  }
  .innerMgContainer {
    flex-direction: column;
    gap: 10px;
  }
  .mobile {
    padding: 0px 6px;
    padding-top: 30px;
  }
  .mobileTrend {
    padding: 30px 6px;
  }
  .ftmobile {
    padding: 0px 6px;
  }
  .ftHeading {
    font-size: 20px;
  }

  .budgetContainer {
    flex-direction: column;
    gap: 10px;
    padding: 15px 3px;
  }

  .budgetImage {
    width: 320px;
    height: 146px;
  }
}
