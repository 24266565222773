.profileContainer {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 60px;
}

/* .profileDetails {
} */

.missingProfile {
  background-color: #fff01f;
  height: 56px;
  width: 100%;
  color: #220700;
  font-family: "Raleway";
  font-size: 16px;
  font-weight: 550;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
}

.pdHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.pHeading {
  font-size: 32px;
  font-weight: bold;
  font-family: "Raleway";
  color: #000000;
}

.breakLine {
  opacity: 40%;
  color: #000000;
}

.billingInfoContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.pdContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 20px;
}

.contentContainer {
  width: 305px;
  display: flex;
  flex-direction: column;
}

.pdLabel {
  font-size: 12px;
  font-family: "Inter";
  font-weight: 400;
  opacity: 50%;
  color: #32353b;
}

.pdDescription {
  color: #3b353b;
  font-size: 18px;
  font-family: "Inter";
  font-weight: bold;
  opacity: 90%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  max-width: 100%;
}

.billSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.bdAddition {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.addNewText {
  font-size: 16px;
  font-weight: 400;
  font-family: "Inter";
}

/* billing info section and pop modal css start from here -------------------------------------------------------------------------------------- */

.billInfoContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 20px;
}

.billInfoHeading {
  font-size: 16px;
  font-family: "Raleway";
  font-weight: bold;
}

.billInfo {
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 4px;
  /* gap: 8px; */
  /* align-items: center; */
  /* justify-content: center; */
  height: 109px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.billUserInfo {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 15px;
  padding: 0px 20px;
  padding-top: 20px;
}

/* Change radio button color */
input[type="radio"] {
  accent-color: #666666;
}

.bsiName {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.username {
  font-size: 14px;
  font-weight: 550;
  font-family: "Inter";
  letter-spacing: -0.5px;
}

.emailPhone {
  font-size: 12px;
  font-weight: 550;
  font-family: "Inter";
  opacity: 60%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.phoneNumber {
  border: none;
  outline: none;
  width: 100%;
  font-size: 16px;
}

.bullet::before {
  content: "•"; /* Unicode for a bullet */
  margin-right: 2px; /* Space between bullet and text */
  color: black; /* Change bullet color if needed */
}

.edit {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.gstpanContainer {
  background-color: #eaf3fd;
  height: 30px;
  display: flex;
  align-items: center;
  margin: 0px 4px;
  border-radius: 4px;
  flex-direction: row;
  gap: 20px;
  padding-left: 45px;
}

.gstpanBillInfo {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.gstpanHeading {
  font-size: 12px;
  font-weight: 550;
  font-family: "Inter";
}

.gstpanNumber {
  font-size: 12px;
  font-weight: 400;
  font-family: "Inter";
}

.addNewBillingBtn {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  font-family: "Inter";
  font-weight: 400;
  cursor: pointer;
}

.billInfoadd {
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  height: 50px;
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); */
  padding-left: 20px;
  cursor: pointer;
  letter-spacing: -0.5px;
}

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1000;
  overflow: hidden; /* Prevent scrolling when modal is open */
}

.modalContent {
  background: #fff;
  width: 450px;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  display: flex;
  flex-direction: column;
  animation: slideIn 0.3s ease-out; /* Animation added */
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.modalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.headerText {
  font-size: 24px;
  font-family: "Raleway";
  font-weight: 550;
}

.billingForm {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.phoneInputProfile {
  /* margin-top: 20px; */
  display: flex;
  gap: 10px;
  border: 1px solid #98999c;
  border-radius: 5px;
  padding: 10px;
  align-items: center;
  width: 100%;
  /* background-color: #e2e2e2; */
  /* width: 100%; */
}

.countryCode {
  border: none;
  font-size: 16px;
  background: none;
  border-right: 1px solid #98999c;
}

.phoneNumber {
  border: none;
  outline: none;
  width: 100%;
  font-size: 16px;
}

.state {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
  width: 100%;
}

.gstpan {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
}

.billingCheckbox {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.checkbox {
  appearance: none; /* Remove default checkbox styling */
  width: 20px;
  height: 20px;
  border: 1px solid #ccc; /* Default border color */
  border-radius: 2px; /* Optional: rounded corners */
  background-color: #fff; /* Default background */
  cursor: pointer;
  outline: none; /* Remove focus outline */
  transition: background-color 0.3s, border-color 0.3s; /* Smooth transition */
  position: relative; /* Needed for the checkmark pseudo-element */
}

.checkbox:checked {
  background-color: #41ca78; /* Background color when checked */
  border-color: #41ca78; /* Border color when checked */
}

.checkbox:checked::after {
  content: ""; /* Add checkmark */
  position: absolute;
  top: 5%;
  left: 35%;
  width: 22%;
  height: 63%;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(50deg);
  display: block;
}

.checkboxText {
  font-size: 12px;
  color: #000;
  font-family: "Inter";
  font-weight: 400;
}

.saveBtn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
