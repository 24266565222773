.likesHeaderSection {
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
}

.containerHeading {
  font-weight: bold;
  font-size: 32px;
  font-family: "Raleway";
}

.containerPills {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
}

.pills {
  background-color: #fff;
  color: #000;
  padding: 8px 30px;
  border: 1px solid #000;
  border-radius: 50px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 98px;
  max-width: 111px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.pills:hover {
  background-color: #000;
  color: #fff;
}

.selectedPill {
  background-color: #000;
  color: #fff;
  border: none;
}

.likesDataSection {
  padding-top: 40px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.tcardContainer {
  background-color: #fff;
  width: 443px;
  height: 86px;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 6px;
}

.trackDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  padding: 3px 6px;
}

.trackInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.trackHeading {
  font-family: "Inter";
  font-family: 550;
  font-size: 16px;
  color: #000;
}

.trackSubHeading {
  opacity: 50%;
  font-size: 12px;
}

.noDataMessage {
  text-align: center;
  font-size: 1.2rem;
  color: #888;
  margin-top: 20px;
}

.emptyHeading {
  /* width: 50%; */
  font-weight: 400;
  font-size: 14px;
  font-family: "Raleway";
  color: #000;
  line-height: 15px;
  margin: 0px auto;
  justify-self: center;
}

.emptyImage {
  height: 358px;
  width: 650px;
  border-radius: 13px;
  margin: 20px auto 0px;
}

.browseBtn {
  margin: 20px auto 0px;
}

.emptyh3 {
  margin: 10px auto 0px;
  justify-self: center;
}
