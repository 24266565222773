.playlist-cards-container {
  --pack-title-font-weight: 550;
  --pack-title-font-size: 16px;

  --pack-card-img-width: 344px;
  --pack-card-img-height: 218px;

  --card-track-total-height: 50px;
  --card-track-total-width: 50px;

  --track-total-font-size: 24px;
  --track-total-font-weight: bolder;
  --track-label-font-size: 8px;
  --track-label-font-weight: bold;

  --discount-percentage-width: 55px;
  --discount-percentage-height: 17px;
  --discount-percentage-font-size: 11px;
  --discount-percentage-font-weight: bold;

  --discount-amount-font-size: 22px;
  --discount-amount-font-weight: bold;
  --discount-label-font-size: 11px;
  --discount-label-font-weight: 400;

  --cost-font-size: 10px;
  --cost-font-weight: 400;

  --explore-btn-width: 110px;
  --explore-btn-height: 35px;
  --explore-btn-font-size: 12px;
  --explore-btn-font-weight: bold;

  --cart-btn-height: 37px;
  --cart-btn-width: 37px;
}

/* All Playlists */
.playlist-cards-container[data-type="allplaylists"] {
  --packs-container-height: max-content;
  /* --pack-card-img-height: 172px; */
  --explore-btn-width: 412px;
  --pack-card-img-width: 435px;
  --pack-card-img-height: 220px;
}

.playlist-cards-container[data-type="pop"] {
  --pack-card-img-width: 435px;
  --explore-btn-width: 415px;
}

/* Similar Track Pack
.playlist-cards-container[data-type="similartrackpack"] {
  --packs-container-height: 303px;
  --pack-title-font-size: 20px;
  --pack-title-font-weight: 550;
  --pack-card-img-width: 438px;
  --pack-card-img-height: 214px;
}

Save More Track
.playlist-cards-container[data-type="savemoretrack"] {
  --packs-container-height: 229px;
  --pack-card-max-width: 336px;
  --discount-amount-font-size: 18px;
  --pack-card-img-width: 332px;
  --pack-card-img-height: 161px;
  --pack-title-font-weight: bold;
  --track-total-font-size: 22px;
} */

/* Top Pack Home */
.playlist-cards-container[data-type="toppackhome"] {
  --pack-card-img-width: 436px;
  --explore-btn-width: 410px;
}

/* Likes Playlist */
.playlist-cards-container[data-type="likesplaylist"] {
  --pack-card-img-width: 365px;
  --pack-card-max-width: 365px;
  --explore-btn-width: 105px;
  --pack-card-img-height: 181px;
}

@media screen and (max-width: 768px) {
  .playlist-cards-container[data-type="toppackhome"] {
    --pack-card-img-width: 238px;
    --pack-card-img-height: 115px;
    --explore-btn-width: 212px;
  }
  .playlist-cards-container[data-type="pop"] {
    --pack-card-max-width: 370px;
    --pack-card-img-width: 350px;
    --explore-btn-width: 320px;
    --pack-card-img-height: 160px;
    justify-content: center;
  }
  .playlist-cards-container[data-type="allplaylists"] {
    --pack-card-max-width: 370px;
    --pack-card-img-width: 350px;
    --explore-btn-width: 320px;
    --pack-card-img-height: 160px;
    justify-content: center;
  }
}

@media screen and (max-width: 481px) {
  .playlist-cards-container[data-type="toppackhome"] {
    --pack-card-img-width: 403px;
    --pack-card-img-height: 187px;
    --explore-btn-width: 380px;
    --pack-card-max-width: 403px;
  }
}

@media screen and (max-width: 375px) {
  .playlist-cards-container[data-type="toppackhome"] {
    --pack-card-img-width: 365px;
    --pack-card-img-height: 187px;
    --explore-btn-width: 338px;
    --pack-card-max-width: 365px;
  }
}
@media screen and (max-width: 320px) {
  .playlist-cards-container[data-type="toppackhome"] {
    --pack-card-img-width: 310px;
    --pack-card-img-height: 187px;
    --explore-btn-width: 280px;
    --pack-card-max-width: 310px;
  }
  .playlist-cards-container[data-type="pop"] {
    --pack-card-max-width: 310px;
    --pack-card-img-width: 290px;
    --explore-btn-width: 260px;
    --pack-card-img-height: 160px;
    justify-content: center;
  }
  .playlist-cards-container[data-type="allplaylists"] {
    --pack-card-max-width: 310px;
    --pack-card-img-width: 308px;
    --explore-btn-width: 290px;
    --pack-card-img-height: 150px;
    justify-content: center;
  }
}
