.custom-button {
  padding: 0 20px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border: 2px solid transparent;
  transition: all 0.2s ease-in-out;
}

/* Hover Effect */
.custom-button.hover-effect:hover {
  transform: scale(1.01);
}

/* Button with background color */
.custom-button[style*="background-color: #000"] {
  color: white;
}

/* Button with transparent background and border */
.custom-button[style*="background-color: transparent"] {
  border-color: #000;
}

/* Button with only icon */
.custom-button.icon-only {
  border: none;
  padding: 0; /* Remove padding for icon-only buttons */
}

/* Button with text and icon */
.custom-button[style*="background-color: #000"] .custom-button-icon {
  color: white;
}

.triple-arrow-icon {
  display: flex;
}

.loaderIcon {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Button should shrink properly on smaller screens */
@media (max-width: 768px) {
  .custom-button {
    padding: 8px 16px;
    font-size: 14px;
    /* height: 36px; */
    /* min-width: 170px; */
    max-width: 190px;
  }
}

/* Extra Small Screens */
@media (max-width: 425px) {
  .custom-button {
    padding: 6px 14px;
    font-size: 12px;
    height: 40px;
    max-width: 190px;
  }

  .triple-arrow-icon {
    transform: scale(0.85);
  }
}

@media (max-width: 320px) {
  .custom-button {
    padding: 6px 14px;
    font-size: 12px;
    height: 38px;
    max-width: 190px;
  }

  .triple-arrow-icon {
    transform: scale(0.85);
  }
}
