/* Parent constainer div */
.pack-listing-container {
  display: flex;
  flex-direction: column;
  /* padding-top: 20px; */
  /* padding-left: 30px; */
  overflow: hidden;
  margin: auto;
  width: 100%;
  margin-top: 75px;
  /* max-width: 1440px; */
}

.outer {
  max-width: 1340px;
  align-self: center;
  /* padding: 0px 50px; */
}

.outer-full {
  width: 100%;
}

.inner {
  max-width: 1340px;
  align-self: center;
  width: 100%;
  /* padding: 0px 50px; */
}

.inner-pl {
  margin: 10px 0px;
}

.ll-innerBreadcrumb {
  color: #a7a1a1;
  font-size: 14px;
  font-family: "Raleway";
  font-weight: 550;
  /* margin: 0px 0px 30px; */
}

.ll-innerBreadcrumb span {
  color: #000000;
}

/* -------------------------------------------------------------------------- */

.pack-listing-description {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 15px;
}

.pack-listing-description h1 {
  font-size: 50px;
  font-family: "Inter";
  font-weight: bold;
  color: #0a0a0a;
  letter-spacing: -2px;
}

.pack-listing-description h2 {
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
  width: 60%;
}

/* top pack card section -------------------------------------------------------------------------------- */

.toppack-container {
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: space-between;
  margin: 10px 0px 20px;
}

.toppack-card-img {
  width: 655px;
  height: 358px;
  border-radius: 10px;
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding: 0px 20px 30px 20px;
  gap: 20px;
  background-color: #683036;
  align-items: center;
}

.toppack-card-img h1 {
  font-size: 32px;
  font-weight: bold;
  font-family: "Inter";
  color: #fff;
}

.toppack-card-img h2 {
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter";
  color: #fff;
}

.toppack-card-title {
  width: 100%;
  line-height: 40px;
  text-align: center;
}

.pack-banner-title-image {
  max-width: 500px;
  height: 70px;
}

.toppack-card-description {
  width: 70%;
}

/* popular packs section--------------------------------------------------------------------------------------------------------  */

.packs-container {
  display: flex;
  flex-direction: column;
  background-color: #ece2c3;
  padding: 5px;
  margin-top: 10px;
  height: 800px;
}

.packs-container-heading {
  color: #010101;
  font-size: 36px;
  font-weight: bold;
  font-family: "Inter";
  letter-spacing: -1.5px;
}

.packs-description-view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
}

.packs-description-view h2 {
  color: #010101;
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter";
  opacity: 80%;
}

@media (max-width: 768px) {
  .toppack-container,
  .pack-listing-description {
    display: none;
  }

  .packs-container {
    height: 950px;
  }
}

@media (max-width: 481px) {
  .packs-container {
    height: 480px;
  }
}
