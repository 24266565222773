:root {
  --packs-container-height: 303px;

  --pack-card-max-width: 436px;

  --pack-title-font-size: 20px;
  --pack-title-font-weight: 550;

  --pack-card-img-width: 435px;
  --pack-card-img-height: 214px;

  --card-track-total-height: 64px;
  --card-track-total-width: 64px;

  --track-total-font-size: 28px;
  --track-total-font-weight: bolder;
  --track-label-font-size: 10px;
  --track-label-font-weight: bold;

  --discount-percentage-width: 70px;
  --discount-percentage-height: 25px;
  --discount-percentage-font-size: 14px;
  --discount-percentage-font-weight: 550;

  --discount-amount-font-size: 28px;
  --discount-amount-font-weight: bold;
  --discount-label-font-size: 11px;
  --discount-label-font-weight: 400;

  --cost-font-size: 12px;
  --cost-font-weight: 400;

  --costspan-font-size: 12px;
  --costspan-font-weight: 400;

  --explore-btn-width: 145px;
  --explore-btn-height: 44px;
  --explore-btn-font-size: 15px;
  --explore-btn-font-weight: bold;

  --cart-btn-height: 46px;
  --cart-btn-width: 46px;
}
