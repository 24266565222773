/* CustomDropdown.css */
.react-select__control {
  border-radius: 4px;
}

.react-select__menu {
  border-radius: 4px;
}

.react-select__menu-list {
  max-height: 200px;
  overflow-y: auto;
}
