.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: auto;
  width: 100%;
  margin: 75px 0px;
  padding: 0px 0px 25px;
  align-items: center;
  font-family: "Raleway";
}

.outer {
  max-width: 1340px;
  align-self: center;
  /* background-color: #f5f7fb; */
}

.outerFull {
  width: 100%;
  padding-top: 15px;
  /* background-color: #f5f7fb; */
}

.inner {
  max-width: 1340px;
  align-self: center;
  width: 100%;
  /* background-color: #f5f7fb; */
  /* margin: auto; */
}

.htuContent {
  text-align: left;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.htuHeading {
  font-size: 46px;
  font-weight: bold;
  letter-spacing: -1.5px;
}

.htuSubheading {
  font-size: 16px;
  width: 663px;
}

.subHeadingSpan {
  font-size: 16px;
  font-weight: 550;
  padding: 20px 0px 00px;
}

.htuSection {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.pillsContainer {
  /* border: 1px solid #d8dde1; */
  /* background-color: #E5E5E5; */
  box-shadow: 0px 0px 16px #E5E5E5;
  padding: 20px;
  width: 98%;
  margin-left: 5px;
  border-radius: 10px;
}

.sectionName {
  padding: 8px 20px;
  background-color: #d8dde1;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  color: black;
  cursor: pointer;
  text-decoration: none;
}

.htuSectionInfo {
  margin: 0px 0px;
  padding: 68px 0px 0px;
}

.sectionheader {
  font-size: 32px;
  font-weight: 700;
  /* padding: 65px 0px 0px; */
}

.sectionSubHeader {
  font-size: 14px;
  width: 100%;
  padding: 5px 0px;
}

.sectionContent {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 20px 0px;
}

.sectionVideo {
  width: 889px;
  height: 500px;
  background-color: #d8dde1;
  border-radius: 10px;
}

.videoTimeStamp {
  width: 442px;
  /* background-color: #d8dde1; */
  border-radius: 10px;
  padding: 0px 20px;
  max-height: 500px;
  overflow-y: auto;
  /* scrollbar-width: thin; */

}

.contenttext {
  font-size: 14px;
  font-weight: 500;
}

.tsInfo {
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 14px;
  font-weight: 550;
  /* max-width: 90%; */
  line-height: 18px;
}

.timestampContainer {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
}

.timestamp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 13px 20px;
  margin: 0px 0px 10px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #d8dde1;
}

.timestamp:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.iframeContainer {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

html {
  scroll-behavior: smooth;
}
