.outer {
  max-width: 1340px;
  align-self: center;
}

.outerFull {
  width: 100%;
  margin: auto;
  /* background-color: #f0f6fa; */
}

.inner {
  max-width: 1340px;
  align-self: center;
  width: 100%;
  /* margin: auto; */
}

.innerBreadcrumb {
  color: #a7a1a1;
  font-size: 14px;
  font-family: "Raleway";
  font-weight: 550;
  margin: 0px 0px 20px;
}

.innerBreadcrumb span {
  color: #000000;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-top: 90px;
  font-family: "Raleway";
}

.trackContainer {
  display: flex;
  flex-direction: row;
  gap: 18px;
  height: auto;
}

.carouselContainer {
  display: flex;
  flex-direction: column;
}

.cardContainer {
  width: 874px;
  border-radius: 10px;
  background-color: #f4fbff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
}

.playlist {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  gap: 40px;
  width: 100%;
  padding: 0px 20px;
  /* align-items: center; */
  justify-content: center;
}

.pContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.cardDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 100%;
}

.tContainerHeight {
  height: 529px;
}

.pContainerHeight {
  height: 300px;
}

.imageT {
  width: 436px;
  height: 220px;
  border-radius: 8px;
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.numberContainer {
  height: 60px;
  width: 60px;
  border-radius: 50px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  flex-direction: column;
}

.totalNumber {
  margin: -7px;
  opacity: 80%;
  color: #000;
  font-size: 24px;
  font-weight: bolder;
}

.ncTxt {
  margin: 7px;
  opacity: 90%;
  color: #000;
  font-size: 10px;
  font-weight: bold;
}

.nametext {
  font-size: 32px;
  font-weight: bold;
  color: #474747;
}

.iconContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.pAbout {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
}

.aboutHeader {
  font-size: 16px;
  font-weight: bold;
  color: #0d0d0d;
  padding: 0px 0px 10px;
  /* margin: 30px 0px 0px; */
  /* height: auto; */
}

.abouttext {
  font-size: 14px;
  color: #0d0d0d;
  opacity: 80%;
  width: 480px;
  line-height: 15pt;
  /* text-align: center; */
}

.pricing {
  width: 438px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  gap: 36px;
}

.pContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.pheader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pheadertxt {
  font-size: 16px;
  font-weight: bold;
}

.infoIcon {
  font-size: 20px;
  font-weight: bold;
}

.pCard {
  height: 220px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: -webkit-fill-available;
}

.cardContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: -webkit-fill-available;
}

.cardHTxt {
  font-size: 20px;
  font-weight: bold;
  color: #0a0a0a;
  line-height: 40px;
}

.cardSubTxt {
  font-size: 12px;
  color: #7e7e7e;
  width: 272px;
  line-height: 16px;
}

.pprice {
  font-size: 22px;
  color: #0a0a0a;
  font-family: "Inter";
  font-weight: 550;
  display: flex;
  align-items: center;
  line-height: 40px;
}

.pcrupee {
  margin-right: 2px;
  font-size: 16px;
}

.pdiscount {
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  background-color: #ffe100;
  font-size: 12px;
  color: #474747;
  font-family: "Inter";
  font-weight: 550;
  line-height: 16px;
}

.cardBtn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: -webkit-fill-available;
}

.knowMoreVideo {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.cardInfo {
  padding: 30px 40px;
  align-items: normal;
  justify-content: normal;
}

.query {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.queryLong {
  margin-top: 10px;
}

.question {
  font-size: 20px;
  font-weight: 550;
  line-height: 16px;
  color: #1b1b1b;
}

.answer {
  color: #1d1d1d;
  font-size: 14px;
  line-height: 20px;
}

.tcBtn {
  width: 220px;
  height: 29px;
  background-color: #f0f6fa;
  border-radius: 20px;
  color: #474747;
  font-size: 10px;
  font-family: "Inter";
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  cursor: pointer;
}

.access {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.accessCard {
  width: 387px;
  height: fit-content;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
}

.accessHTxt {
  color: #1b1b1b;
  font-size: 14px;
  line-height: 16px;
}

.accessItem {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 15px;
}

.allowedIcon {
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #fff;
  background-color: #48b02c;
  border-radius: 50px;
}

.notallowedIcon {
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #fff;
  background-color: #f44336;
  border-radius: 50px;
}

.accesstxt {
  color: #1b1b1b;
  line-height: 20px;
  font-size: 14px;
}

.heading {
  font-size: 20px;
  font-weight: bold;
  line-height: 16px;
  color: #474747;
}

.subheading {
  font-size: 14px;
  line-height: 16px;
  color: #474747;
  opacity: 60%;
}

.info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ciCard {
  width: 387px;
  height: auto;
  background-color: #fefefe;
  border-radius: 10px;
  padding: 20px 30px;
}

.ciCardContent {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.ciHtxt {
  font-size: 12px;
  /* font-family: "Inter"; */
  font-weight: bold;
  line-height: 24px;
  color: #0d0d0d;
}

.cisubHtxt {
  font-size: 14px;
  /* font-family: "Inter"; */
  line-height: 20px;
  color: #0d0d0d;
}

.ciPillSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}

.ciPills {
  margin: 0px;
  font-size: 14px;
  font-family: "Raleway";
  font-weight: 550;
  color: #0d0d0d;
  opacity: 80%;
  width: fit-content;
  background-color: #0d0d0d1a;
  padding: 8px 12px;
  border-radius: 5px;
}

.carouselBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 18px;
  margin-top: 20px;
}

.caroCard {
  height: 40px;
  min-width: 40px;
  padding: 10px;
  font-size: 18px;
  font-weight: 550;
  background-color: white;
  border: 2px solid #dfd7f3;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
}

.caroCard.expanded {
  min-width: 120px;
  background-color: #dfd7f3;
  border: none;
  padding: 15px 15px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Icon styles */
.icon {
  color: #707070;
  transition: color 0.3s ease;
  margin-top: 4px;
}

.expandedIcon {
  color: black;
}

/* Text styles */
.text {
  margin-left: 8px;
  font-size: 14px;
  color: #333;
  transition: font-weight 0.3s ease;
}

.boldText {
  font-weight: bold;
}

.cardContainerp {
  width: 1340px;
}

/* track section new css design ---------------------------------------------------- */

.catsTracks {
  display: flex;
  flex-direction: column;
  margin: 30px 0px;
}

.ppTrackCards {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 0px 0px 20px 20px;
  flex-wrap: wrap;
  background-color: white;
  border-radius: 0px 0px 10px 10px;
  border-bottom: 4px solid #dedede;
  border-right: 1px solid #dedede;
  border-left: 1px solid #dedede;
  /* box-shadow: 0px 0px 8px #0d0d0d4f; */
}

.theading {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 0px 10px 20px;
  flex-wrap: wrap;
  background-color: white;
  border-radius: 10px 10px 0px 0px;
  border-top: 1px solid #dedede;
  border-right: 1px solid #dedede;
  border-left: 1px solid #dedede;
}

.theadingTxt {
  display: flex;
  flex-direction: row-reverse;
  font-size: 14px;
  margin-top: 15px;
}

.buttonContainer {
  text-align: center;
  width: -webkit-fill-available;
  margin-top: 5px;
}

/* create playlist pop css start from here -------------------------------------------------------------------------------------------------------- */

@keyframes slideInAndShake {
  0% {
    transform: translateY(-100%) translateX(0);
    opacity: 0;
  }
  50% {
    transform: translateY(20px) translateX(-10px);
    opacity: 1;
  }
  70% {
    transform: translateY(10px) translateX(10px);
  }
  90% {
    transform: translateY(0px) translateX(-5px);
  }
  100% {
    transform: translateY(0) translateX(0);
  }
}

.collectionContainer {
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
}

.collectionContent {
  width: 389px;
  height: auto;
  max-height: 476px;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* Animation */
  animation: slideInAndShake 0.4s ease-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.popupHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pHText {
  font-weight: bold;
  font-family: "Raleway";
  font-size: 26px;
}

.cpinput {
  width: 334px;
  height: 59px;
  border-radius: 8px;
  background-color: #fff;
  font-size: 16px;
  font-family: "Raleway";
  font-weight: 400;
  border: 1px solid #fff;
  padding-left: 15px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  outline: none;
}

.cpinput:focus {
  outline: none;
}

.existingCollection {
  width: 334px;
  height: 60px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  padding: 4px;
}

.playlistHeading {
  font-family: "Raleway";
  font-size: 18px;
  font-weight: 550;
  display: flex;
  align-items: center;
  margin-left: 10px;
  gap: 5px;
}

.pimagecard {
  border-radius: 5px;
  width: 80px;
  height: 52px;
  position: relative;
  background-size: cover;
  background-position: center;
}

.existingpsection {
  max-height: 390px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px 0px;
}

.rcontainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.rcard {
  width: 252px;
  height: 152px;
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.rHeader {
  display: flex;
  align-items: center;
  gap: 12px;
}

.rIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  background-color: #dadada;
  border-radius: 50%;
}

.rImg {
  height: 24px;
  width: 24px;
}

.rHtxt {
  font-size: 14px;
  font-weight: 550;
  color: #0d0d0d;
  /* letter-spacing: 0px; */
}

.rSubTxt {
  color: #1d1d1d;
  font-size: 12px;
  line-height: 18px;
  margin-top: 5px;
}

/* responsive screens */

@media (max-width: 768px) {
  .outer {
    max-width: 768px;
  }
  .outerFull {
    width: 100%;
  }
  .inner {
    width: 100%;
    align-items: center;
  }
  .container {
    margin-top: 70px;
  }
  .cardContainer {
    width: 100%;
    border-radius: 0px;
    box-shadow: none;
  }
  .trackContainer {
    flex-direction: column;
    height: auto;
  }
  .carouselBtn {
    padding: 0px 15px;
    margin-bottom: 20px;
  }
  .pricing {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-around;
    gap: 10px; */
    width: 100%;
    padding: 0px 15px;
  }
  .pContainer {
    width: 360px;
  }
  .pCard {
    padding: 15px;
  }
  .cardSubTxt {
    width: 230px;
  }
  .cardContainerp {
    width: 768px;
  }
}

@media (max-width: 430px) {
  .outer {
    max-width: 430px;
  }
  .outerFull {
    width: 100%;
  }
  .inner {
    width: 100%;
    align-items: center;
  }
  .carouselContainer {
    height: auto;
  }
  .tContainerHeight {
    height: 340px;
  }
  .cardContainer {
    height: 340px;
  }
  .imageT {
    height: 152px;
    width: 152px;
  }
  .plyBtnContainer {
    height: 34px;
    width: 34px;
    font-size: 16px;
  }
  .nametext {
    font-size: 24px;
  }
  .artisttext {
    font-size: 10px;
  }
  .abouttext {
    font-size: 10px;
    width: 390px;
  }
  .pricing {
    flex-direction: column;
  }
  .pContainer {
    width: 360px;
  }
  .catscardslice {
    margin-left: 22px;
  }
  .overflow {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 340px;
  }
  .access,
  .info {
    flex-direction: column;
    justify-content: normal;
    gap: 20px;
  }
  .ciCard,
  .accessCard {
    width: auto;
  }
  .pCard {
    justify-content: normal;
    gap: 30px;
    height: auto;
  }
  .rcontainer {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .rcard {
    width: auto;
    height: auto;
  }
  .cardContainerp {
    width: 430px;
  }
}

@media (max-width: 414px) {
  .catscardslice {
    margin-left: 16px;
  }
  .cardContainerp {
    width: 414px;
  }
}

@media (max-width: 390px) {
  .catscardslice {
    margin-left: 4px;
  }
  .cardContainerp {
    width: 390px;
  }
}
