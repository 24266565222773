/* General styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Parent constainer div */
.parentDiv {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  min-height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

/* customizable snowflake styling */
.snowflake {
  color: #FFF;
  font-size: 1em;
  font-family: Inter;
  /* text-shadow: 2px 2px #D5D5D5; */
}

.sf-orange {
  color: #FF9933;
  text-shadow: 0px 0px 3px #f1be8a;
}

.sf-blue {
  color: #000088;
  text-shadow: 1px 1px #272786;
}

.sf-green {
  color: #128807;
  text-shadow: 1px 1px #128807;
}

/* @keyframes snowflakes-fall {
  0% {
    top: -10%;
  }
  100% {
    top: 100%;
  }
} */

@keyframes snowflakes-fall {
  0% {
    top: 100%;
    /* left: -10%; */
  }
  100% {
    top: -10%;
    /* right: 100% */
  }
}

@keyframes snowflakes-shake {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(80px);
  }
  100% {
    transform: translateX(0px);
  }
}

.snowflake {
  position: fixed;
  top: -10%;
  z-index: 9999;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;

  /* Updated animation properties */
  -webkit-animation-name: snowflakes-fall, snowflakes-shake;
  /* -webkit-animation-name: snowflakes-fall; */
  -webkit-animation-duration: 10s, 2s;
  -webkit-animation-timing-function: linear, ease-in-out;
  -webkit-animation-iteration-count: 2, 25; /* Finite iterations */
  -webkit-animation-fill-mode: forwards; /* Retain final state after animation */
  /* animation-name: snowflakes-fall; */
  animation-name: snowflakes-fall, snowflakes-shake;
  animation-duration: 10s, 2s;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: 2, 25 ; /* Finite iterations */
  animation-fill-mode: forwards; /* Retain final state after animation */
  opacity: 1; /* Ensure snowflakes are visible */
  transition: opacity 2s ease-in-out; /* Fade out after animation */
}

.snowflake:nth-of-type(0) {
  left: 1%;
  -webkit-animation-delay: 0s, 0s;
  animation-delay: 0s, 0s;
}

.snowflake:nth-of-type(1) {
  left: 10%;
  -webkit-animation-delay: 1s, 1s;
  animation-delay: 1s, 1s;
}

.snowflake:nth-of-type(2) {
  left: 20%;
  -webkit-animation-delay: 6s, 0.5s;
  animation-delay: 6s, 0.5s;
}

.snowflake:nth-of-type(3) {
  left: 30%;
  -webkit-animation-delay: 4s, 2s;
  animation-delay: 4s, 2s;
}

.snowflake:nth-of-type(4) {
  left: 40%;
  -webkit-animation-delay: 2s, 2s;
  animation-delay: 2s, 2s;
}

.snowflake:nth-of-type(5) {
  left: 50%;
  -webkit-animation-delay: 8s, 3s;
  animation-delay: 8s, 3s;
}

.snowflake:nth-of-type(6) {
  left: 60%;
  -webkit-animation-delay: 6s, 2s;
  animation-delay: 6s, 2s;
}

.snowflake:nth-of-type(7) {
  left: 70%;
  -webkit-animation-delay: 2.5s, 1s;
  animation-delay: 2.5s, 1s;
}

.snowflake:nth-of-type(8) {
  left: 80%;
  -webkit-animation-delay: 1s, 0s;
  animation-delay: 1s, 0s;
}

.snowflake:nth-of-type(9) {
  left: 90%;
  -webkit-animation-delay: 3s, 1.5s;
  animation-delay: 3s, 1.5s;
}
