.searchPageContainer {
  margin-top: 75px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%; /* Full width */
}

.outerFull {
  width: 100%;
}

.searchPageInnerContainer {
  max-width: 1340px;
  align-self: center;
  width: 100%;
  margin: 0 auto; /* Center it horizontally */
}

.innerBreadcrumb {
  color: #a7a1a1;
  font-size: 14px;
  font-family: "Raleway";
  font-weight: 550;
  margin: 0px 0px 30px;
}

.innerBreadcrumb span {
  color: #000;
}

.searchTabs {
  display: flex;
  margin-bottom: 20px;
  border-radius: 5px;
  /* border-bottom: 1px solid #ddd; */
}

/* .tab {
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  text-align: center;
  transition: background-color 0.3s ease;
} */

.tab {
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1.05rem;
  text-align: center;
  transition: background-color 0.3s ease;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  margin: 0px 10px 0px 0px;
  border-radius: 20px;
}

.tab:hover {
  background-color: #e0e0e0;
}

.tabActive {
  background-color: var(--button-primary);
  color: white;
  border-radius: 20px;
}

.tabActive:hover {
  color: #000000;
}

.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  width: 65%;
  justify-content: center;
  /* height: 45vh; */
  /* background: #f6f7c84d; */
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.1);
  /* margin: auto; */
  border-radius: 15px;
  padding: 30px 0px;
  margin: 0px auto;
  /* margin: 0px 20px; */
}

.noDataImg {
  width: 100%;
  max-width: 250px;
  height: auto;
}

.noDataLyric {
  font-size: 14px;
  font-weight: 550;
  font-family: "Raleway";
  color: #000;
  /* letter-spacing: -1px; */
  font-style: italic;
  padding: 20px 60px;
  background:#f6f7c84d;
  border-radius: 10px;
}

.trackData {
  width: 100%;
}

.tabContent {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
}

.searchContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
  width: 100%;
}

.searchHeading {
  font-size: 36px;
  font-weight: bold;
  font-family: "Raleway";
  color: #000;
  letter-spacing: -1px;
}

.clearFilterText {
  font-size: 16px;
  font-weight: bold;
  font-family: "Raleway";
  cursor: pointer;
}

.clearFilterText:hover {
  color: #918f8f;
}

.searchHeadingSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.viewMoreBtn {
  padding: 15px 45px;
  margin: 20px auto;
  display: block;
  font-size: 16px;
  color: white;
  background-color: #000;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.viewMoreBtn:hover {
  background-color: #000;
}

.filters {
  position: sticky;
  top: 0px;
  align-self: flex-start;
  /* z-index: 1000; */
}

.rotateButton {
  position: fixed;
  left: 0; /* Align to the right */
  top: 30%; /* Center vertically */
  /* transform: translateY(-50%) rotate(90deg); Center without rotating the button itself */
  /* background-color: #000; */
  background: linear-gradient(gray, black);
  color: #fff;
  padding: 90px 0px 125px;
  border: none;
  /* border-radius: 5px; */
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
  z-index: 1000; /* Ensure it's above other elements */
  font-size: 14px;
  /* transition: background-color 0.3s ease; */
  display: flex; /* Use flexbox for alignment */
  flex-direction: column; /* Arrange items in a row */
  align-items: center;
  /* justify-content: space-between; */
  gap: 60px;
  width: 55px; /* Adjust width to content */
}

.rotateButton:hover {
  background-color: #555;
}

.rotateButton span {
  transform: rotate(90deg); /* Rotate the text inside the button */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  display: inline-block; /* Ensures the rotation is applied to the text */
  text-align: center; /* Center the text horizontally */
  font-size: 18px;
  color: #fff; /* Ensures text color is visible on dark background */
  text-shadow: 2px 2px 3px #000;
}

.filterIcon {
  /* font-size: 24px; Adjust size of the icon */
  /* color: var(--button-primary); Use your primary color variable */
  color: #ffffff;
}

.filterActiveContainer {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: green;
}

.filterActive {
  color: white;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 1000;
}

.modalOverlayWithPlayer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 90px); /* Adjusted height when global player is active */
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 1000;
}

.modalContainer {
  width: 450px;
  height: 100%;
  background-size: cover;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  align-items: center;
  background-color: #f7f7f7;
  animation: slideIn 0.3s ease-out; /* Animation added */
}

.heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.header {
  /* height: 60px; Fixed height */
  flex: 0 0 60px; /* Fixed height, won't shrink */
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
}

.headingtext {
  font-size: 26px;
  font-family: "Raleway";
  font-weight: bold;
}

.filtersSection {
  /* background-color: #555; */
  width: 100%;
  padding: 8px 20px;
  height: auto;
  font-size: 20px;
}

.filterHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;
}

.sortOptions {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
}

.radioInput {
  margin-right: 10px;
}

.filterWapper {
  overflow-y: auto;
  width: 100%;
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@media (max-width: 768px) {
  .rotateButton {
    padding: 30px 0px 70px;
    top: 38%;
    width: 30px;
    font-size: 14px;
  }
  .filterActiveContainer {
    width: 25px;
    height: 25px;
  }
}

@media (max-width: 425px) {
  .searchHeading {
    font-size: 22px;
  }
  .rotateButton {
    width: 30px;
  }
  .clearFilterText {
    font-size: 13px;
  }
  .filterActiveContainer {
    width: 25px;
    height: 25px;
  }
}

@media (max-width: 375px) {
  .searchHeading {
    font-size: 20px;
  }
  .rotateButton {
    width: 25px;
  }
  .clearFilterText {
    font-size: 12px;
  }
  .filterActiveContainer {
    width: 25px;
    height: 25px;
  }
}

@media (max-width: 320px) {
  .searchHeading {
    font-size: 14px;
  }
  .rotateButton {
    width: 25px;
  }
  .clearFilterText {
    font-size: 12px;
  }
  .filterActiveContainer {
    width: 25px;
    height: 25px;
  }
}
