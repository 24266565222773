.container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-weight: bold;
    position: relative;
}
.box {
    position: relative;
    color: #ffffff;
}
.box1 {
    opacity:0.3;
}
.box2 {
    left: 0px;
    margin-left: 0px;
    opacity: 0.5;
}
.box3 {
    left: 0px;
    margin-left: 0px;
    opacity: 0.8;
}