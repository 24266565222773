.trackCard {
  width: 74px;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
}

.trackPlayBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  height: 40px;
  width: 40px;
  border-radius: 50px;
  cursor: pointer;
}
