:root {
  /* Primary Colors */
  /*  PRIMARY BUTTON ELEMENTS */
  /* --button-primary: #007bff; */
  --button-primary: #ff004c;
  --button-primary-half: #ff004c77;
  --button-primary-hover: #0056b3;
  --button-primary-disabled: #80bdff;

  /* Secondary Colors */
  --button-secondary: #6c757d; /* Default secondary button color */
  --button-secondary-hover: #5a6268; /* Hover state for secondary */
  --button-secondary-disabled: #d6d8db; /* Disabled secondary button color */

  /* Disabled Button */
  --button-disabled: #e9ecef; /* General disabled button background */
  --button-disabled-text: #6c757d; /* Text color for disabled buttons */

  /* Background Colors */
  --background-error: #f8d7da; /* Error background */
  --background-warning: #fff3cd; /* Warning background */
  --background-success: #d4edda; /* Success background */

  /* Text Colors */
  --text-error: red; /* Error text color */
  --text-warning: #856404; /* Warning text color */
  --text-success: #155724; /* Success text color */

  /* General Colors */
  --text-default: #212529; /* Default text color */
  --background-default: #ffffff; /* Default background color */
}
