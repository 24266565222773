.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContainer {
  width: 1190px;
  height: 549px;
  background: no-repeat center center;
  background-size: cover;
  border-radius: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-image: url(https://cdn.hooprsmash.com/web/onboarding/login-gradient.png);
}

.googleSignIn {
  height: 40px;
  width: 175px;
  margin: 0px auto;
}

.innerContent {
  width: 450px;
  height: 100%;
  background: #ffffff55;
  border-radius: 0px 10px 10px 0px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: relative;
}

.login {
  width: 100%;
  padding: 0px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: antiquewhite; */
}

.loginText {
  font-family: "Raleway";
  font-weight: bold;
  font-size: 26px;
  color: #121418;
}

.phoneInput {
  margin-top: 20px;
  display: flex;
  /* gap: 10px; */
  border: 1px solid #bfbfbf;
  border-radius: 5px;
  /* padding: 0px 15px; */
  align-items: center;
}

.countryCode {
  border: none;
  font-size: 18px;
  background: none;
  border-right: 1px solid #bfbfbf;
  padding: 0px 10px;
  height: 50px;
  background-color: #fff;
}

.phoneNumber {
  border: none;
  outline: none;
  width: 100%;
  height: 50px;
  font-size: 18px;
  padding: 0px 10px 0px 20px;
  letter-spacing: 1px;
  margin-right: 2px;
}

.getOtpButton {
  background-color: var(--button-primary); /* Updated background color */
  color: white;
  border: none;
  padding: 15px 0;
  border-radius: 30px; /* Updated border radius */
  font-size: 16px;
  font-weight: 550;
  font-family: "Inter";
  cursor: pointer;
  display: flex;
  justify-content: center; /* Horizontally center text */
  align-items: center; /* Vertically center text */
  width: 100%;
  margin-top: 20px;
}

.separator {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 40px 0;
  color: #828282;
  font-size: 14px;
}

.separator::before,
.separator::after {
  content: "";
  flex-grow: 1;
  height: 1px;
  background: #bfbfbf;
}

.googleButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid #bfbfbf;
  padding: 8px 0;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  font-family: "Inter";
  font-weight: 400;
  width: 100%;
  background-color: #fff;
  color: #000;
}

.googleIcon {
  width: 32px;
  height: 32px;
  object-fit: contain;
}

.privacy {
  font-size: 12px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: #828282;
  text-align: center;
  margin-bottom: 20px;
  width: 80%;
}

.privacy a {
  font-weight: bold;
  text-decoration: none;
  color: black;
}

.checkboxContainer {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.otpNumber {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.otp {
  font-family: "Inter";
  font-weight: 550;
  font-size: 16px;
  color: #454545;
}

.edit {
  font-family: "Inter";
  font-weight: 550;
  font-size: 16px;
  color: #1f7ae0;
  cursor: pointer;
}

.otpContainer {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
}

.otpInput {
  width: 58px;
  height: 58px;
  border: 1px solid #bfbfbf;
  border-radius: 5px;
  background-color: white;
  font-size: 32px;
  font-family: "Inter";
  font-weight: bold;
  text-align: center;
  outline: none;
  transition: all 0.3s ease;
}

.otpInput:focus {
  border: none;
  background-color: #454545;
  color: white;
}

.otpFilled {
  background-color: #454545;
  color: white;
}

.resend {
  color: #454545;
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
}

.resendSpan {
  text-decoration: underline;
  cursor: pointer;
}

.profileContent {
  width: 450px;
  height: 100%;
  background: white;
  border-radius: 0px 10px 10px 0px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  padding: 30px;
  position: relative;
}

.closeIcon {
  display: flex;
  justify-content: flex-end;
}

.profileText {
  color: #121418;
  font-size: 24px;
  font-family: "Raleway";
  font-weight: bold;
  width: 80%;
  margin: 15px 0px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  /* width: 100%; */
  margin-top: 20px;
}

.verfiyProfile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* gap: 8px; */
}

.profileVerifyBtn {
  width: 87px;
  height: 50px;
  background-color: var(--button-primary);
  color: #fff;
  font-size: 14px;
  font-family: "Raleway";
  font-weight: 550;
  border-radius: 30px;
  border: none;
  cursor: pointer;
}

.emailVerified {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background-color: #1fad40;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonContainercp {
  /* width: 100%; */
  display: flex;
  margin-top: 20px;
}

.skip {
  color: #454545;
  font-family: "Raleway";
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-self: center;
  text-decoration: underline;
  margin-top: 10px;
  cursor: pointer;
}

.phoneInputProfile {
  /* margin-top: 20px; */
  display: flex;
  gap: 10px;
  border: 1px solid #98999c;
  border-radius: 5px;
  padding: 10px;
  align-items: center;
  width: 290px;
  /* background-color: #e2e2e2; */
  /* width: 100%; */
}

.otpContainerProfile {
  display: flex;
  justify-content: space-between;
}

.otpInputProfile {
  width: 45px;
  height: 45px;
  border: 1px solid #bfbfbf;
  border-radius: 5px;
  background-color: white;
  font-size: 32px;
  font-family: "Raleway";
  font-weight: bold;
  text-align: center;
  outline: none;
  transition: all 0.3s ease;
}

.otpInputProfile:focus {
  border: none;
  background-color: #454545;
  color: white;
}

.otpFilled {
  background-color: #454545;
  color: white;
}

.resend {
  color: #454545;
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
  margin-top: 8px;
}

.closeIconMain {
  position: absolute;
  top: 20px;
  right: 20px;
}

@media screen and (max-width: 768px) {
  .modalContainer {
    width: 600px;
    height: 300px;
  }
  .innerContent {
    width: 270px;
  }
  .loginText {
    font-size: 20px;
  }
  .countryCode,
  .phoneNumber {
    font-size: 12px;
    height: 35px;
    padding: 0px;
  }
  .phoneNumber {
    padding: 0px 0px 0px 5px;
  }
  .getOtpButton {
    padding: 10px 0px;
    font-size: 12px;
  }
  .privacy {
    font-size: 9px;
  }
}

@media screen and (max-width: 481px) {
  .modalContainer {
    background-image: none;
    width: 400px;
    height: 350px;
    justify-content: center;
  }
  .profileContent {
    width: 350px;
    border-radius: 10px;
    padding-top: 10px;
    height: auto;
  }
  .profileText {
    font-size: 16px;
  }
  .buttonContainercp {
    justify-content: center;
  }
  .inputContainer {
    margin: 0;
  }
  .innerContent {
    border-radius: 10px;
    background-color: #fff;
    width: 350px;
  }
  .loginText {
    font-size: 28px;
  }
  .countryCode,
  .phoneNumber {
    font-size: 18px;
    height: 50px;
    /* padding: 0px 10px; */
  }
  .phoneNumber {
    padding: 0px 0px 0px 5px;
  }
  .getOtpButton {
    padding: 15px 0px;
    font-size: 16px;
  }
  .privacy {
    font-size: 12px;
    margin-top: 10px;
  }
  .otpInput {
    width: 38px;
    height: 38px;
    font-size: 22px;
  }
  .widthEmail {
    width: 190px;
  }
}
