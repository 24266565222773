.collectionHeading {
  font-family: "Raleway";
  font-size: 32px;
  font-weight: bold;
  color: #000;
  margin-bottom: 30px;
}

.collContentEmpty {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex-wrap: wrap;
  margin: auto;
}

.emptyHeading {
  font-weight: 400;
  font-size: 14px;
  font-family: "Raleway";
  color: #000;
  line-height: 15px;
  margin: 0px auto;
  justify-self: center;
}

.emptyImage {
  height: 358px;
  width: 650px;
  border-radius: 13px;
  margin: 20px auto 0px;
}

.browseBtn {
  margin: 20px auto 0px;
}

.emptyh3 {
  margin: 10px auto 0px;
  justify-self: center;
}

/* main collection section css ----------------------------------------------------- */

.collSection {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
}

.collCard {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* cursor: pointer; */
}

.card {
  width: 215px;
  height: 215px;
  border-radius: 10px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  position: relative;
  background-size: cover;
  background-position: right;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 10px;
  cursor: pointer;
}

.collMenuContainer {
  width: 35px;
  height: 35px;
  background-color: #fff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.menuDropdown {
  position: absolute; /* Ensure it is positioned relative to the container */
  top: 100%; /* Position it below the container */
  right: 0; /* Align it to the right edge of the container */
  background-color: white;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  z-index: 10; /* Ensure it appears above other elements */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0px;
  min-width: 120px;
}

.menuDropdown p {
  padding: 5px 10px;
  cursor: pointer;
  transition: background 0.3s;
}

.menuDropdown p:hover {
  background: var(--button-primary);
  color: #fff;
}

.renameContainer {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 215px;
}

.renameInput {
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 125px;
}

.saveButton {
  background: var(--button-primary);
  color: white;
  padding: 7px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.collNameContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.collName {
  font-size: 20px;
  font-weight: 550;
  font-family: "Raleway";
  color: #000;
}

.itemCount {
  font-size: 14px;
}

.selectedCollection {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.scHeader {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  font-size: 20px;
  font-weight: 550;
  font-family: "Raleway";
  color: #000;
  cursor: pointer;
}

.containerPills {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
}

.pills {
  background-color: #fff;
  color: #000;
  padding: 8px 30px;
  border: 1px solid #000;
  border-radius: 50px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 98px;
  max-width: 111px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.pills:hover {
  background-color: #000;
  color: #fff;
}

.selectedPill {
  background-color: #000;
  color: #fff;
  border: none;
}

.scData {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
