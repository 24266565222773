.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-family: "Raleway";
  margin-top: 8%;
  padding: 0px 20px;
  text-align: center;
}

.cartImg {
  width: 104px;
  height: 104px;
}

.heading {
  font-weight: bold;
  font-size: 24px;
  color: #000;
}

.subHeading {
  font-weight: 400;
  font-size: 14px;
  color: #000;
}

.bottomText {
  margin: 40px 0px;
}
