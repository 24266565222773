.input-container {
  position: relative;
  width: 100% !important;
}

.input-box {
  padding: 10px;
  box-sizing: border-box;
  outline: none;
}

.input-label {
  position: absolute;
  top: 10px;
  left: 10px;
  transition: all 0.2s ease-out;
  pointer-events: none;
  background: white;
  padding: 0 5px;
  color: #a2a2a2;
  font-size: 14px;
}

.input-box:focus + .input-label,
.input-box:not(:placeholder-shown) + .input-label {
  top: -10px;
  left: 20px;
  font-size: 10px;
  /* background: linear-gradient(to bottom, #ffffff 35%, #ffffff 10%); */
  background: linear-gradient(to bottom, #00000000 0%, #ffffff 50%);
  color: #000;
}

.input-box:focus {
  border: 1px solid #000;
}

.label-text {
  font-size: 14px;
  margin: 5px 0px;
  color: #000;
  font-weight: 550;
}

.error-text {
  color: var(--text-error);
  font-size: 12px;
  margin-top: 4px;
  padding: 5px 15px;
}

.infoSpan {
  font-size: 12px;
  font-style: italic;
  font-family: "Inter";
  color: #777777;
  margin: -18px 0px 10px;
  padding: 5px 15px;
  /* background-color: #f0f1d9; */
  border-radius: 5px;
}
