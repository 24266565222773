/* Parent constainer div */
.listing-container {
  display: flex;
  flex-direction: column;
  /* padding-top: 20px; */
  /* padding-left: 30px; */
  overflow: hidden;
  margin: auto;
  width: 100%;
  margin-top: 75px;
  /* max-width: 1440px; */
}

.tl-innerBreadcrumb {
  color: #a7a1a1;
  font-size: 14px;
  font-family: "Raleway";
  font-weight: 550;
  /* margin: 0px 0px 30px; */
}

.tl-innerBreadcrumb span {
  color: #000;
}

.outer {
  max-width: 1340px;
  align-self: center;
  /* padding: 0px 50px; */
}

.outer-full {
  width: 100%;
}

.inner {
  max-width: 1340px;
  align-self: center;
  width: 100%;
  /* padding: 0px 50px; */
}

/* -------------------------------------------------------------------------- */

.track-listing-description {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-left: 0px;
}

.track-listing-description h1 {
  font-size: 50px;
  font-family: "Inter";
  font-weight: bold;
  color: #0a0a0a;
  letter-spacing: -2px;
}

.track-listing-description h2 {
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
}

/* track card container --------------------------------------------------- */
.track-cards-container {
  display: flex;
  gap: 18px;
  /* padding-left: 30px; */
  /* padding-right: 60px; */
  /* justify-content: space-between; */
}

.track-listingcard {
  height: 363px;
  background-color: #683036;
  border-radius: 15px;
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  /* max-width: 640px;
  width: 332px; */
}

.text-overlay {
  color: white;
  /* background-color: rgba(0, 0, 0, 0.5); semi-transparent bg for debug */
  width: 95%;
  height: 95%;
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
  justify-content: flex-end;
}

.card-play-button {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  height: 54px;
  width: 54px;
  border-radius: 50px;
  background-color: #fff;
  margin-top: 10px;
}

.card-description {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.card-description h1 {
  font-size: 26px;
  font-weight: bold;
  font-family: "Raleway";
  color: #fff;
}

.card-description h2 {
  font-size: 14px;
  font-weight: 550;
  font-family: "Raleway";
  color: #fff;
}

/* popular track cards container --------------------------------------------------- */

.popular-track-cards-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #ece2c3;
  margin-top: 30px;
  /* padding-bottom: 30px; */
  padding: 20px 30px 20px 30px;
  width: 100%;
  height: auto;
}

.ptctext {
  font-size: 36px;
  font-weight: bold;
  font-family: "Raleway";
  color: #220700;
  margin-bottom: 15px;
  letter-spacing: -1.5px;
}

.popular-track-cards {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-bottom: 15px;
}

.popular-track-card {
  height: 425px;
  width: 256px;
  background-color: #fff;
  padding: 5px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
}

.popular-track-card-image {
  height: 244px;
  width: 244px;
  border-radius: 5px;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.3);
  background-color: #4f4f4f;
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popular-track-card-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 40%;
  padding: 0px 6px;
  /* gap: 5px; */
}

.popular-track-title {
  display: flex;
  flex-direction: column;
}

.popular-track-title {
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.popular-track-title h1 {
  font-size: 20px;
  font-weight: bold;
  font-family: "Raleway";
  color: #000;
}

.popular-track-title h2 {
  font-size: 14px;
  font-weight: 550;
  font-family: "Raleway";
  color: #000;
  opacity: 60%;
  margin-top: -12px;
}

.popular-track-price-container {
  display: flex;
  flex-direction: column;
}

.popular-track-discount-price {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.popular-track-discount-amount {
  display: flex;
  flex-direction: row;
}

.popular-track-discount-amount span {
  margin: 0px;
  color: #030202;
  font-size: 11px;
  font-weight: 400;
  font-family: "Inter";
  margin-top: 4px;
}

.popular-track-discount-amount p {
  margin: 0px;
  color: #000000;
  font-size: 24px;
  font-weight: bold;
  font-family: "Inter";
}

.popular-track-discount-percentage {
  background-color: #f5d707;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 61px;
  height: 19px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 550;
  font-family: "Inter";
  color: #000000;
}

.popular-track-cost-price {
  display: flex;
  flex-direction: row;
}

.popular-track-cost-price span {
  margin: 0px;
  color: #808080;
  font-size: 13px;
  font-weight: 400;
  font-family: "Inter";
  text-decoration: line-through;
}

.popular-explore-track-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.marketing-card-image {
  height: 414px;
  width: 252px;
  position: relative;
  background-size: cover;
  background-position: center;
  border-radius: 13px;
}

.marketing-banner {
  width: 1320px;
  height: 180px;
  position: relative;
  background-size: cover;
  background-position: center;
  border-radius: 13px;
}

.marketing-banner-img {
  width: 1340px;
  height: 180px;
  border-radius: 13px;
}

@media (max-width: 768px) {
  .track-listing-description {
    width: 100%;
    padding-left: 13px;
  }
  .track-listing-description h1 {
    font-size: 32px;
  }
  .popular-track-cards-container {
    padding: 5px;
  }
  .track-cards-container,
  .track-listing-description {
    display: none;
  }
}
