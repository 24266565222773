.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContainer {
  width: 600px;
  height: auto;
  padding: 30px;
  /* height: 100%; */
  background-size: cover;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* justify-content: flex-end; */
  align-items: center;
  background-color: #f7f7f7;
  animation: slideIn 0.3s ease-out; /* Animation added */
}

.iconContainer {
  height: 52px;
  width: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #41ca78;
  border-radius: 50px;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.modalHeading {
  font-family: "Raleway";
  font-size: 36px;
  font-weight: bold;
}

.modalSubHeading {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  width: 311px;
  text-align: center;
  opacity: 70%;
  color: #000;
}

.btnContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}

.redirectText {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
}

.countdownText {
  text-align: center;
  font-size: 14px;
  color: #666;
  margin-top: 15px;
}
