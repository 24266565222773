/* Parent constainer div */
.categoriesListingContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: auto;
  width: 100%;
  margin-top: 75px;
}

.outer {
  max-width: 1340px;
  align-self: center;
}

.outerFull {
  width: 100%;
  background-color: #ece2c3;
  height: 435px;
  padding-top: 15px;
}

.inner {
  max-width: 1340px;
  align-self: center;
  width: 100%;
}

.innerBreadcrumb {
  color: #D5D5D5;
  font-size: 14px;
  font-family: "Raleway";
  font-weight: 550;
}

.innerBreadcrumb span {
  color: #000;
}

/* categories description section -------------------------------------------------------------------------- */

.categoriesListingDescription {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 15px;
}

.categoriesHeading {
  font-size: 50px;
  font-family: "Inter";
  font-weight: bold;
  color: #0a0a0a;
  letter-spacing: -1.5px;
}

.categoriesSubHeading {
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
}

/* categories main banner section -------------------------------------------------------------------------- */

.categoriesListingBanner {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 8px;
  padding-left: 30px;
  padding-bottom: 30px;
  height: 351px;
  /* background-color: aqua; */
  border-radius: 15px;
  position: relative;
  background-size: cover;
  background-position: center;
}

.categoriesBannerHeading {
  font-size: 56px;
  font-family: "Raleway";
  font-weight: bold;
  color: #ffffff;
  line-height: 40pt;
}

.categoriesBannerSubHeading {
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
  color: #ffffff;
}

/* popular categories section --------------------------------------------------------------- */

.popularcategoriesContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
}

.popularcategoriesHeading {
  font-size: 36px;
  font-family: "Raleway";
  font-weight: bold;
  color: #211414;
  letter-spacing: -1px;
}

.popularcategoriesCards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* gap: 10px; */
  margin-top: 10px;
  justify-content: space-between;
  padding-right: 1px;
}

.popularcategoriesCard {
  flex: 0 1 calc(25% - 10px);
  box-sizing: border-box;
  height: 354px;
}

.popularcategoriesCardImage {
  /* width: 322px; */
  width: 100%;
  height: 322px;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  box-shadow: 0px 4px 6px #ddd;
}

.popularcategoriesCardHeading {
  font-size: 20px;
  font-family: "Inter";
  font-weight: bold;
}

/* allcategories section --------------------------------------------------------------- */

.allcategoriesCards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 11px;
  margin-top: 10px;
}

.allcategoriesCard {
  height: 277px;
  margin-bottom: 20px;
}

.allcategoriesCardImage {
  width: 258px;
  height: 254px;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  box-shadow: 0px 4px 6px #ddd;
}

.allcategoriesCardHeading {
  font-size: 18px;
  font-family: "Raleway";
  font-weight: bold;
}

/* tablet view css start from here  -------------------------------------------*/
@media screen and (max-width: 768px) {
  .outer {
    max-width: 768px;
    width: 100%;
    padding: 0 10px;
  }
  .inner {
    width: 100%;
  }
  .outerFull {
    padding: 0 10px;
    height: 294px;
  }
  .categoriesHeading {
    font-size: 34px;
  }
  .categoriesSubHeading {
    font-size: 13px;
  }
  .categoriesListingBanner {
    width: 748px;
  }
  .popularcategoriesHeading {
    margin-top: 10px;
  }
  .popularcategoriesCardImage {
    width: 180px;
    height: 180px;
  }
  .allcategoriesCardImage {
    width: 241px;
    height: 230px;
  }
  .allcategoriesCard {
    height: auto;
    margin: 0;
  }
}

/* mobile view css start from here  -------------------------------------------*/
@media screen and (max-width: 481px) {
  .outer {
    max-width: 481px;
    width: 100%;
    padding: 0 10px;
  }
  .inner {
    width: 100%;
  }
  .outerFull {
    padding: 0 10px;
    height: auto;
  }
  .categoriesHeading {
    font-size: 28px;
  }
  .categoriesSubHeading {
    font-size: 12px;
  }
  .categoriesListingBanner {
    width: 370px;
    height: 120px;
    border-radius: 8px;
  }
  .popularcategoriesHeading {
    margin-top: 10px;
  }
  .popularcategoriesCardImage {
    width: 177px;
    height: 170px;
  }
  .allcategoriesCardImage {
    width: 178px;
    height: 180px;
  }
  .allcategoriesCard {
    height: auto;
    margin: 0;
  }
  .popularcategoriesCard {
    height: auto;
  }
  .popularcategoriesCardHeading {
    margin-bottom: 15px;
  }
  .allcategoriesCards {
    justify-content: center;
  }
  .popularcategoriesCards {
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
  .categoriesBannerHeading {
    line-height: 12pt;
    font-size: 14px;
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 375px) {
  .outer {
    max-width: 375px;
    width: 100%;
    padding: 0 10px;
  }
  .inner {
    width: 100%;
  }
  .outerFull {
    padding: 0 10px;
    height: auto;
  }
  .categoriesHeading {
    font-size: 22px;
  }
  .categoriesSubHeading {
    font-size: 10px;
  }
  .categoriesListingBanner {
    width: 340px;
  }
  .popularcategoriesHeading {
    margin-top: 10px;
  }
  .popularcategoriesCardImage {
    width: 160px;
    height: 160px;
  }
  .allcategoriesCardImage {
    width: 160px;
    height: 160px;
  }
  .allcategoriesCard {
    height: auto;
    margin: 0;
  }
  .popularcategoriesCard {
    height: auto;
  }
  .popularcategoriesCardHeading {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 320px) {
  .outer {
    max-width: 320px;
    width: 100%;
    padding: 0 10px;
  }
  .inner {
    width: 100%;
  }
  .outerFull {
    padding: 0 10px;
    height: auto;
  }
  .categoriesHeading {
    font-size: 18px;
  }
  .categoriesSubHeading {
    font-size: 10px;
  }
  .categoriesListingBanner {
    width: 280px;
  }
  .popularcategoriesHeading {
    margin-top: 10px;
  }
  .popularcategoriesCardImage {
    width: 170px;
    height: 160px;
  }
  .allcategoriesCardImage {
    width: 160px;
    height: 150px;
  }
  .allcategoriesCard {
    height: auto;
    margin: 0;
  }
  .popularcategoriesCard {
    height: auto;
  }
  .popularcategoriesCardHeading {
    margin-bottom: 15px;
  }
}
