.gp-inner {
  max-width: 1340px;
  align-self: center;
  width: 100%;
}

.global-player-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  background-color: #000;
  font-family: "Inter";
}

.globalplayer {
  height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  background-color: #000;
  justify-content: space-between;
}

.g-player-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
}

.g-track-container {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}

.g-track-img {
  height: 62px;
  width: 62px;
  border-radius: 5px;
}

.g-track-info {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.g-track-info h1 {
  font-size: 16px;
  font-weight: 400;
  color: #f3f5f4;
}

.g-track-info h2 {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  opacity: 40%;
}

.g-player-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.g-waveform-container {
  width: 750px;
  display: flex;
  align-items: center;
}

.g-player-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 18px;
  margin: 0px 15px;
  cursor: pointer;
}

.g-player-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 2;
}

.g-player-close:hover {
  opacity: 0.8;
}

.gp-actions-container {
  margin-right: 100px;
}

.g-player-mob-btn {
  display: none;
}

@media screen and (max-width: 481px) {
  .g-pricing-btn {
    display: none;
  }
  .gp-actions-container {
    display: none;
  }
  .gp-inner {
    max-width: 481px;
  }
  .g-player-container {
    justify-content: normal;
    gap: 10px;
  }
  .g-waveform-container {
    width: auto;
  }
  .g-track-info h1 {
    font-size: 14px;
  }
  .g-track-info h2 {
    font-size: 10px;
  }
  .g-track-container {
    margin-left: 10px;
    gap: 9px;
  }
  .g-player-mob-btn {
    position: absolute;
    top: 50px;
    right: 10px;
    cursor: pointer;
    z-index: 2;
    display: block;
    width: 25px;
    height: 25px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 6px rgba(255, 255, 255, 0.3);
  }
  .g-player-options-mob {
    position: absolute;
    bottom: 45px; /* Adjust to position above the player */
    right: 10px; /* Align to the right */
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    padding: 10px;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .g-player-options-mob div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  .g-player-options-mob div:hover {
    background-color: #f0f0f0;
  }
}
