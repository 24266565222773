/* Parent constainer div */
.genresListingContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: auto;
  width: 100%;
  margin-top: 75px;
}

.outer {
  max-width: 1340px;
  align-self: center;
}

.outerFull {
  width: 100%;
  background-color: #ece2c3;
  height: 300px;
  padding-top: 15px;
}

.inner {
  max-width: 1340px;
  align-self: center;
  width: 100%;
}

.innerBreadcrumb {
  color: #D5D5D5;
  font-size: 14px;
  font-family: "Raleway";
  font-weight: 550;
}

.innerBreadcrumb span {
  color: #000;
}

/* genres description section -------------------------------------------------------------------------- */

.genresListingDescription {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 15px;
}

.genresHeading {
  font-size: 50px;
  font-family: "Inter";
  font-weight: bold;
  color: #0a0a0a;
  letter-spacing: -1.5px;
}

.genresSubHeading {
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
}

/* genres main banner section -------------------------------------------------------------------------- */

.genresListingBanner {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 8px;
  padding-left: 30px;
  padding-bottom: 30px;
  height: 351px;
  border-radius: 15px;
  position: relative;
  background-size: cover;
  background-position: center;
}

.genresBannerHeading {
  font-size: 56px;
  font-family: "Raleway";
  font-weight: bold;
  color: #ffffff;
  line-height: 50pt;
  letter-spacing: -1.5px;
  margin-bottom: 15px;
}

.genresBannerSubHeading {
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
  color: #ffffff;
}

/* popular genres section --------------------------------------------------------------- */

.populargenresContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
}

.populargenresHeading {
  font-size: 36px;
  font-family: "Raleway";
  font-weight: bold;
  color: #211414;
  letter-spacing: -1px;
  margin-top: 10px;
}

.populargenresCards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 9px;
  margin-top: 10px;
  /* justify-content: space-between; */
  padding-right: 1px;
}

.populargenresCard {
  box-sizing: border-box;
  height: 160px;
}

.populargenresCardImage {
  width: 440px;
  /* width: 100%; */
  height: 160px;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  box-shadow: 0px 4px 6px #ddd;
}

.populargenresCardHeading {
  font-size: 20px;
  font-family: "Inter";
  font-weight: bold;
  margin-top: 5px;
}

/* allgenres section --------------------------------------------------------------- */

.allgenresCards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 9px;
  margin-top: 10px;
  /* justify-content: space-between; */
}

.allgenresCard {
  height: 165px;
  margin-bottom: 40px;
}

.allgenresCardImage {
  width: 440px;
  height: 160px;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  box-shadow: 0px 4px 6px #ddd;
}

.allgenresCardHeading {
  font-size: 18px;
  font-family: "Raleway";
  font-weight: bold;
  margin-top: 5px;
}

@media screen and (max-width: 768px) {
  .outer {
    max-width: 768px;
    width: 100%;
    padding: 0 10px;
  }
  .inner {
    width: 100%;
  }
  .outerFull {
    padding: 0 10px;
    padding-bottom: 40px;
    height: auto;
  }
  .genresListingBanner {
    width: 748px;
  }
  .populargenresCards {
    flex-direction: column;
    gap: 40px;
  }
  .populargenresCardImage {
    width: 747px;
  }
  .allgenresCardImage {
    width: 368px;
  }
}

@media screen and (max-width: 481px) {
  .outer {
    max-width: 481px;
    width: 100%;
    padding: 0 10px;
  }
  .inner {
    width: 100%;
  }
  .outerFull {
    padding: 0 10px;
    padding-bottom: 40px;
    height: auto;
  }
  .genresListingBanner {
    width: 370px;
    height: 120px;
    border-radius: 8px;
  }
  .populargenresCards {
    flex-direction: column;
    gap: 40px;
  }
  .populargenresCardImage {
    width: 370px;
  }
  .allgenresCardImage {
    width: 180px;
  }
  .allgenresCards {
    justify-content: center;
  }
  .populargenresCards {
    align-items: center;
  }
  .allgenresCardHeading {
    font-size: 16px;
  }
  .genresBannerHeading {
    line-height: 30pt;
    font-size: 30px;
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 375px) {
  .outer {
    max-width: 481px;
    width: 100%;
    padding: 0 10px;
  }
  .inner {
    width: 100%;
  }
  .outerFull {
    padding: 0 10px;
    padding-bottom: 40px;
    height: auto;
  }
  .genresListingBanner {
    width: 340px;
  }
  .populargenresCards {
    flex-direction: column;
    gap: 40px;
  }
  .populargenresCardImage {
    width: 340px;
  }
  .allgenresCardImage {
    width: 162px;
  }
  .allgenresCardHeading {
    font-size: 16px;
  }
}

@media screen and (max-width: 320px) {
  .outer {
    max-width: 320px;
    width: 100%;
    padding: 0 10px;
  }
  .inner {
    width: 100%;
  }
  .outerFull {
    padding: 0 10px;
    padding-bottom: 40px;
    height: auto;
  }
  .genresListingBanner {
    width: 280px;
  }
  .populargenresCards {
    flex-direction: column;
    gap: 40px;
  }
  .populargenresCardImage {
    width: 300px;
  }
  .allgenresCardImage {
    width: 132px;
  }
  .allgenresCardHeading {
    font-size: 14px;
  }
}
