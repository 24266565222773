.myAccountContainer {
  display: flex;
  min-height: 100vh;
  width: 100%;
  margin-top: 65px;
}

.leftNav {
  background-color: #181b20;
  width: 227px;
  padding: 20px;
  box-sizing: border-box;
}

.navHeading {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 5px;
}

.breakLine {
  color: #fff;
  opacity: 40%;
}

.tabsList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tabItem {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  padding: 10px 0;
  cursor: pointer;
}

.tabItem:hover {
  color: #c8c64d;
}

.activeTab {
  color: #c8c64d;
}

.rightContent {
  flex-grow: 1;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}
