.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1000;
}

.modalContainer {
  width: 450px;
  height: 100%;
  background-size: cover;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  /* align-items: center; */
  background-color: #fff;
  animation: slideIn 0.3s ease-out; /* Animation added */
  /* padding: 0px 20px; */
  padding-bottom: 15px;
}

.content {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  /* background-color: #ece2c3; */
  max-height: 90vh;
  padding-bottom: 20px;
}

.heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.header {
  flex: 0 0 65px; /* Fixed height, won't shrink */
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
}

.headingtext {
  font-size: 26px;
  font-family: "Raleway";
  font-weight: bold;
}

.cardContainer {
  height: 76px;
  width: 408px;
  background-color: #ece2c3;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 6px;
  gap: 15px;
  margin: 0px 20px;
}

.cardImg {
  height: 62px;
  width: 62px;
  background-color: #590e0e;
  border-radius: 3px;
  position: relative;
  background-size: cover;
  background-position: center;
}

.cardTitile {
  font-family: 18px;
  font-weight: bold;
  font-family: "Raleway";
  color: #000;
}

.cardSubTitle {
  font-family: 14px;
  font-weight: 400;
  font-family: "Raleway";
  color: #000;
  opacity: 60%;
}

.title {
  font-family: 14px;
  font-weight: 550;
  font-family: "Inter";
  color: #000;
}

.container {
  margin: 20px 20px 0px 20px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.textArea {
  width: 408px;
  height: 115px;
  border-radius: 5px;
  border: 1px solid #bfbfbf;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  font-size: 14px;
  color: #333;
  resize: none; /* Prevents resizing */
  outline: none; /* Removes outline on focus */
}

.textArea::placeholder {
  color: #bfbfbf;
  font-size: 14px;
}

.dateInput {
  width: 408px;
  height: 50px;
  border: 1px solid #bfbfbf;
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
  color: #333;
  font-family: "Inter", sans-serif;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  outline: none;
  cursor: pointer;
}

/* .dateInput:focus {
  border-color: var(--button-primary);
} */

.reqSubmitBtn {
  /* margin: 20px 20px 0px 20px; */
  display: flex;
  justify-content: center;
  margin-top: 10px;
  /* flex-direction: column;
  gap: 14px; */
  /* position: absolute;
  bottom: 10px; */
}
