@import "./trackvariables.css";
@import "./tracktype-variable.css";

/* .allsong-track-card {
  height: 324px;
  width: 194px;
  background-color: #fff;
  padding: 5px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
} */

.allsong-track-card-container {
  display: var(--card-flex);
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--card-gap-container);
  margin-top: 1px;
}

.allsong-track-card {
  height: var(--card-height);
  width: var(--card-width);
  background-color: var(--card-background-color);
  padding: var(--card-padding);
  /* padding-bottom: 10px; */
  display: flex;
  flex-direction: column;
  gap: var(--card-gap);
  align-items: center;
  border-radius: 13px;
  box-shadow: var(--card-box-shadow);
}

/* .allsong-track-card-image {
  height: 184px;
  width: 184px;
  border-radius: 5px;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.3);
  background-color: #4f4f4f;
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.allsong-track-card-image {
  height: var(--image-height);
  width: var(--image-width);
  border-radius: 10px;
  box-shadow: var(--image-shadow);
  /* background-color: var(--image-backgroundcolor); */
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
  overflow: hidden; /* Ensures overlay stays within bounds */
  cursor: pointer;
  margin-top: 4px;
}

.allsong-track-card-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0); /* Initially transparent */
  transition: background-color 0.3s ease; /* Smooth transition */
  border-radius: inherit; /* Matches the parent’s border-radius */
  z-index: -1; /* Places overlay above background but below other elements */
}

.track-play-btn-container {
  width: var(--play-btn-width);
  height: var(--play-btn-height);
  border-radius: 50%;
  background-color: #fff;
  margin-bottom: 15px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Initially hidden */
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease; /* Smooth transition for visibility */
  position: relative; /* Ensures it creates its own stacking context */
  z-index: 1; /* Places it above the overlay */
}

/*  THIS IS NOT EFFECTIVE AT THE MOMENT */
.allsong-track-card-image:hover::before {
  /* Add semi-transparent black overlay */
  background-color: rgba(0, 0, 0, 0.5);
}

.allsong-track-card-image:hover .track-play-btn-container {
  /* Show play button container */
  opacity: 1;
  visibility: visible;
  z-index: 0;
}

.allsong-track-card-text {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  width: 100%;
  height: var(--card-text-height);
  padding: var(--card-text-padding);
  gap: 14px;
}

.allsong-track-title {
  display: flex;
  flex-direction: column;
}

.allsong-track-title h1 {
  font-size: var(--card-title-font-size);
  font-weight: bold;
  font-family: "Raleway";
  color: var(--card-title-font-color);
}

.allsong-track-title h2 {
  font-size: var(--track-title-font-size);
  font-weight: 550;
  font-family: "Raleway";
  color: var(--track-title-font-color);
  opacity: 60%;
  margin-top: 2px;
}

.allsong-track-price-container {
  display: flex;
  flex-direction: column;
}

.allsong-track-discount-price {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  letter-spacing: -1.5px;
}

.allsong-track-discount-amount {
  display: flex;
  flex-direction: row;
  letter-spacing: var(--card-discount-amt-letter-spacing);
  opacity: 0.9;
  /* color: #101720; */
}

.allsong-track-discount-amount span {
  margin: 0px;
  color: var(--discount-font-color);
  font-size: var(--discount-font-size);
  font-weight: 400;
  font-family: "Inter";
  margin-top: 1px;
  letter-spacing: var(--discount-font-letter-space);
}

.allsong-track-discount-amount p {
  margin: 0px;
  color: var(--discount-amt-color);
  font-size: var(--discount-p-font-size);
  /* font-weight: 550; */
  font-weight: var(--discount-amt-font-weight);
  font-family: "Inter";
  letter-spacing: var(--discount-font-letter-space);
}

.allsong-track-discount-percentage {
  background-color: #f5d707;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 19px;
  border-radius: 2px;
  /* font-size: 12px; */
  font-size: var(--discount-percentage-font-size-track);
  font-weight: 550;
  font-family: "Inter";
  color: #000000;
  padding: 0px 4px;
}

.allsong-track-cost-price {
  display: flex;
  flex-direction: row;
}

.allsong-track-cost-price span {
  margin: 0px;
  color: var(--track-cost-price-color);
  font-size: var(--cost-price-font-size);
  font-weight: var(--cost-price-font-weight);
  font-family: "Inter";
  text-decoration: line-through;
  opacity: 0.7;
}

.allsong-explore-track-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: var(--explore-track-gap);
}

.all-songs-viewall p {
  color: #000;
  font-size: var(--view-all-p-font-size);
  font-weight: 550;
  font-family: "Raleway";
  margin: 0;
}

.all-songs-viewall {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
  margin-right: 15px;
}

.vocal-tag {
  width: 38px;
  height: 34px;
  position: relative;
}

/* css for marketing cards---------------------------------------------------------- */

.marketing-card {
  height: 414px;
  width: 252px;
}

.marketing-card-image {
  height: 414px;
  width: 252px;
  position: relative;
  background-size: cover;
  background-position: center;
  border-radius: 13px;
}

.marketing-banner {
  width: 1320px;
  height: 180px;
  position: relative;
  background-size: cover;
  background-position: center;
  border-radius: 13px;
}

.marketing-banner-img {
  width: 1340px;
  height: 180px;
  border-radius: 13px;
}

@media screen and (max-width: 768px) {
  .marketing-card,
  .marketing-card-image {
    height: 380px !important;
    width: 228px !important;
  }
}

@media screen and (max-width: 481px) {
  .marketing-card,
  .marketing-card-image {
    height: 320px !important;
    width: 166px !important;
  }
}

@media screen and (max-width: 375px) {
  .marketing-card,
  .marketing-card-image {
    height: 300px !important;
    width: 156px !important;
  }
}

@media screen and (max-width: 320px) {
  .marketing-card,
  .marketing-card-image {
    height: 265px !important;
    width: 130px !important;
  }
}
